import axios from 'axios';
//import { set } from 'lodash';

const HOST = process.env.REACT_APP_SOCKET_HOST || 'localhost:3030';

const myaxios = axios.create({
  baseURL: HOST
});

myaxios.interceptors.request.use(function (config) {
  return config;
});

export default myaxios;
