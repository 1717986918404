import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import {
  isMobile
} from 'react-device-detect';
import { get } from 'lodash';
import useImageDownloader from 'hooks/useImageDownloader';
import GameControls from './GameControls';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  headerActionRoot: {
    '& > .MuiCardHeader-action': {
      margin: 'auto'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    //backgroundColor: '#590087'
  },
  dividerRoot: {
    maxWidth: theme.spacing(2),
    '& > .MuiDivider-root': {
      margin: 'auto'
    }
  },
  warningRoot: {
    textAlign: 'center'
  },
  chipRoot: {
    borderRadius: 3,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  }
}));

export default function GameCard ({ productInfo }) {
  const productName = get(productInfo, 'name');
  const productType = get(productInfo, 'type');
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const logoType = isMobile ? 'mobile' : 'web';
  const productLogoId = get(productInfo, `logos.${logoType}.file`);
  const { image: logo, ready: logoReady } = useImageDownloader(productLogoId);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      {
        !logoReady && <LinearProgress />
      }
      <CardActionArea onClick={handleExpandClick}>
        <CardHeader
          avatar={
            <Avatar aria-label='ct-icon' className={classes.avatar} src={logo} />
          }
          action={
            <ExpandMoreIcon color='primary' className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })} />
          }
          title={<Typography variant='h6'>{productName}</Typography>}
          subheader={<Chip className={classes.chipRoot} size='small' color='primary' label={t(`gameType.${productType}`)}/>}
        />
      </CardActionArea>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Divider light />
        {
          expanded && <GameControls productInfo={productInfo} />
        }
      </Collapse>
    </Card>
  );
}

GameCard.propTypes = {
  productInfo: PropTypes.object.isRequired,
};
