//https://blog.wick.technology/pwa-install-prompt/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import useIosPrompt from './useIosPrompt';
import useWebPrompt from './useWebPrompt';

import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  actionLogo: {
    marginBottom: -theme.spacing(.5),
  },
}));

export default function PwaPrompt () {
  const [ iosInstallPrompt, handleIosInstallDeclined ] = useIosPrompt();
  const [ webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted ] = useWebPrompt();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const currentPath = get(location, 'pathname');

  const generateDialogContent = () => {

    if (iosInstallPrompt) return (
      <>
        {
          t(`Tap`)
        }
        <span>
          &nbsp;&nbsp;
        </span>
        <img className={classes.actionLogo} alt="Action Button" src="/images/ios_action.png" />
        <span>
          &nbsp;&nbsp;
        </span>
        {
          t(`PWA Prompt Ios`)
        }
      </>
    );

    if (webInstallPrompt) return (
      <>
        {
          t(`PWA Prompt Web`)
        }
      </>
    );

    return 'N/A';
  };

  if (currentPath === '/signin' || currentPath === '/signup') return null;

  if (!iosInstallPrompt && !webInstallPrompt) return null;

  return (
    <div>
      <Dialog
        open={true}
        onClose={iosInstallPrompt ? handleIosInstallDeclined : handleWebInstallDeclined}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t(`PWA Prompt Title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {
              generateDialogContent()
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={iosInstallPrompt ? handleIosInstallDeclined : handleWebInstallDeclined} color='primary'>
            { t(`No`) }
          </Button>
          {
            iosInstallPrompt ? null :
            <Button onClick={handleWebInstallAccepted} autoFocus>
              { t(`Ok`) }
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
