import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BalanceContext from 'features/balanceContext/balanceContext';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    textAlign: 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardActionsRoot: {
    minHeight: theme.spacing(10)
  },
  dividerRoot: {
    maxWidth: theme.spacing(2),
    '& > .MuiDivider-root': {
      margin: 'auto'
    }
  }
}));

function BalanceCard (props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { balance } = useContext(BalanceContext);
  const { children, onTxnClick } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant='h6' display='block' gutterBottom>
          {t(`My Balance`)}
        </Typography>
        <Typography variant='h3' component='h2'>
          {balance}
        </Typography>
      </CardContent>
      <Divider light />
      <CardActions className={classes.cardActionsRoot}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={5}>
            <Button fullWidth size='large' onClick={e => { onTxnClick(e, 'dp') }} variant='contained'>{t(`Deposit`)}</Button>
          </Grid>
          <Grid item xs={2} className={classes.dividerRoot}>
            <Divider light orientation='vertical' />
          </Grid>
          <Grid item xs={5}>
            <Button fullWidth size='large' onClick={ e => { onTxnClick(e, 'wd') }} color='primary' variant='contained'>{t(`Withdrawal`)}</Button>
          </Grid>
        </Grid>
      </CardActions>
      {
        children
      }
    </Card>
  );
}

export default BalanceCard;

BalanceCard.propTypes = {
  onTxnClick: PropTypes.func.isRequired,
};
