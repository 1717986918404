import { useState, useEffect } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt';

const _PROMPT_NAME = 'webLastPrompt';

const useWebPrompt = () => {
  const [ installPromptEvent, setInstallPromptEvent ] = useState();
  const [ userShouldBePrompted, handleUserSeeingPrompt ] = useShouldShowPrompt(_PROMPT_NAME);

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();

      if (userShouldBePrompted) setInstallPromptEvent(event);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }
  });

  const handleInstallDeclined = () => {
    handleUserSeeingPrompt();
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = () => {
    if (!installPromptEvent) {
      handleUserSeeingPrompt();
      setInstallPromptEvent(null);
      return;
    }
    installPromptEvent.prompt();

    installPromptEvent.userChoice.then(choice => {
      //if (choice.outcome !== 'accepted') handleUserSeeingPrompt();
      handleUserSeeingPrompt();
      setInstallPromptEvent(null);
    });
  };

  return [ installPromptEvent, handleInstallDeclined, handleInstallAccepted ];
};

export default useWebPrompt;
