export const initialState = {
  credential: null,
  lastLogin: null,
  lastLogout: null,
  lastAction: null,
  isInGame: false,
  status: 'idle',
  depositAmount: 0
};

export default function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_CREDENTIAL':
      return {
        ...state,
        credential: action.data
      };
    case 'TRIGGER_LOGIN':
      return {
        ...state,
        lastLogin: new Date().getTime(),
        lastAction: new Date().getTime(),
        depositAmount: action.data
      };
    case 'TRIGGER_LOGOUT':
      return {
        ...state,
        lastLogout: new Date().getTime(),
        lastAction: new Date().getTime()
      }
    case 'USER_IN_GAME':
      return {
        ...state,
        isInGame: true
      };
    case 'USER_NOT_IN_GAME':
      return {
        ...state,
        isInGame: false
      };
    case 'UPDATE_IS_IN_GAME':
      return {
        ...state,
        isInGame: action.data
      };
    default:
      throw new Error(`Action ${action.type} not supported by reducer.`);
  }
};
