import {
  authenticateStart,
  authenticateSucceed,
  authenticateFailed,
  clearErrorMessage,
  logout,
} from './authSlice';
import {
  initUserAction
} from 'features/user/userActions';
import feathers from 'services/feathers';
import ability from 'casl/ability';
import { get } from 'lodash';
import i18n from 'i18n';
import { USER_PENDING_ERROR } from 'constants/Constants';

export const authenticateAsync = credential => async dispatch => {
  dispatch(authenticateStart());
  try {
    const authResp = await feathers.authenticate({
      strategy: 'local',
      username: credential.username,
      password: credential.password,
      token: credential.token
    });

    const role = get(authResp, 'user.role');

    if (role !== 'user') {
      dispatch(logoutAsync());
    } else {
      const user = get(authResp, 'user');
      const rules = get(authResp, 'rules', []);
      const locale = get(authResp, 'user.locale.code', 'en');
      ability.update(rules);
      i18n.changeLanguage(locale);

      dispatch(authenticateSucceed({ rules }));
      dispatch(initUserAction(user));
    }
  } catch (err) {
    const errorMessage = get(err, 'message', 'Unknown error');
    dispatch(authenticateFailed({ errorMessage }));
    if (errorMessage === USER_PENDING_ERROR) throw err;
  }
};

export const reAuthenticateAsync = () => async dispatch => {
  dispatch(authenticateStart());
  try {
    const authResp = await feathers.reAuthenticate()
    const user = get(authResp, 'user');
    const rules = get(authResp, 'rules', []);
    const locale = get(authResp, 'user.locale.code', 'en');
    ability.update(rules);
    i18n.changeLanguage(locale);

    dispatch(authenticateSucceed({ rules, user }));
    dispatch(initUserAction(user));
  } catch (err) {
    const errorMessage = get(err, 'message', 'Unknown error');
    dispatch(authenticateFailed({ errorMessage }));
    if (errorMessage === USER_PENDING_ERROR) throw err;
  }
};

export const logoutAsync = () => async dispatch => {

  await feathers.logout();
  await feathers.io.close();
  await feathers.io.open();

  // Clear ability
  ability.update([]);
  dispatch(logout());
};

export const clearErrorMessageAction = () => async dispatch => {
  dispatch(clearErrorMessage());
};
