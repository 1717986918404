import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import LastTenTxn from '@material-ui/icons/FormatListNumberedRtl';
import GroupIcon from '@material-ui/icons/Group';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUsername } from 'features/user/userSelectors';
import { useSelector } from 'react-redux';

const ListItemWithNav = (props) => {
  const { icon, path, title } = props;
  return (
    <ListItem
      button
      key={title}
      component={NavLink} to={{
        pathname: path,
      }} activeClassName='Mui-selected'
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

ListItemWithNav.propTypes = {
  icon: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const MainListItems = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ListItemWithNav icon={<LastTenTxn />} path='/txnlogs' title={t(`Latest Transactions`)} />
      <ListItemWithNav icon={<GroupIcon />} path='/referral' title={t(`Referral Program`)} />
    </div>
  );
};

export const SecondaryListItems = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ListItemWithNav icon={<SettingsIcon />} path='/settings' title={t(`Settings`)} />
    </div>
  );
};

export const SignOutListItem = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();
  const username = useSelector(getUsername);

  return (
    <ListItem
      button
      key='SignOut'
      onClick={onClick}
    >
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText primary={`${t(`Sign Out`)}`} secondary={username} />
    </ListItem>
  );
};

SignOutListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
};