import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import { find, get } from 'lodash';
import axiosClient from 'services/axios/anonymous';

const useStyles = makeStyles((theme) => ({
  regionFormControl: {
    margin: theme.spacing(1),
    //width: '100%',
    '& > .MuiInput-root': {
      minWidth: 120
    }
  },
}));

function useRegionSelect (modelName, serviceName) {
  const [ region, setRegion ] = useState('en');
  const [ regionId, setRegionId ] = useState(null);
  const [ countryCode, setCountryCode ] = useState('');
  const [ status, setStatus ] = useState('loadRegions');
  const [ regionLookup, setRegionLookup ] = useState([]);
  const [ message, setMessage ] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (status !== 'loadRegions') return;
    let isMounted = true;
    async function fetchRegions () {
      try {
        const regions = await axiosClient.get('/regions?$sort[name]=1');
        if (isMounted) setRegionLookup(regions.data.data);
      } catch (err) {
        const errorMessage = get(err, 'response.data.message');
        setMessage(`Failed to fetch region. (${errorMessage})`);
      }
      if (isMounted) setStatus('idle');
    };
    fetchRegions();

    return () => {
      isMounted = false;
    };
  }, [status]);

  useEffect(() => {
    if (regionLookup.length <= 0) return;
    const regionCode = get(regionLookup, '[0].code');
    setRegion(regionCode);
  }, [regionLookup]);

  useEffect(() => {
    const findRegion = find(regionLookup, { code: region });
    if (!findRegion) return;
    setRegionId(findRegion._id);
    setCountryCode(findRegion.countryCode);
  }, [region, regionLookup]);

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const generateRegionSelect = () => {
    if (regionLookup.length <= 1) return (
      <SnackbarMessage id='region-sel-snackbar' message={message} setMessage={setMessage} />
    );
    return (
      <div>
        <FormControl className={classes.regionFormControl}>
          <InputLabel htmlFor='lang-native'>Region</InputLabel>
          <NativeSelect
            inputProps={{
              name: 'region',
              id: 'region-native',
            }}
            value={region}
            onChange={handleRegionChange}
          >
            {
              regionLookup.map(r => {
                return <option key={r.code} value={r.code}>{r.name}</option>
              })
            }
          </NativeSelect>
        </FormControl>
        <SnackbarMessage id='region-sel-snackbar' message={message} setMessage={setMessage} />
      </div>
    );
  };

  return {
    regionSelectReady: status === 'idle',
    region: region,
    generateRegionSelect,
    regionId,
    countryCode
  }
};

export default useRegionSelect;