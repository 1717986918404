import { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { getUserId } from 'features/user/userSelectors';
import { useSelector } from 'react-redux';
import { get, filter } from 'lodash';

function useFeathersService (serviceName, query = {}) {
  const service = feathers.service(`/${serviceName}`);
  const userId = useSelector(getUserId);
  const [ fetchedData, setFectchedData ] = useState([]);
  const [ status, setStatus ] = useState('fetch');
  const [ error, setError ] = useState(null);

  useEffect(() => {
    setFectchedData([]);
    setStatus('fetch');
    setError(null);
  }, [userId]);

  useEffect(() => {
    let isMounted = true;
    if (!userId || status !== 'fetch') return;
    async function fetch () {
      try {
        const find = await service.find(query);
        if (isMounted) {
          const total = get(find, 'total');

          // Fetch all will return [] instead of { total: x...}
          if (total === undefined) setFectchedData(find);
          else setFectchedData(find.data);
        }
      } catch (err) {
        //console.error(err);
        if (isMounted) setError(err);
      }
      if (isMounted) setStatus('idle');
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [userId, status, query, service]);

  useEffect(() => {
    if (status !== 'idle' || !userId) return;

    const onAddedOrChanged = (data) => {
      let newData = filter(fetchedData, (d) => {
        return d._id !== data._id
      });
      newData.push(data);
      setFectchedData(newData);
    };

    const onDeleted = (data) => {
      let newData = filter(fetchedData, (d) => {
        return d._id !== data._id
      });
      setFectchedData(newData);
    };

    service.on('created', onAddedOrChanged);
    service.on('removed', onDeleted);
    service.on('updated', onAddedOrChanged);

    return () => {
      service.removeListener('created', onAddedOrChanged);
      service.removeListener('removed', onDeleted);
      service.removeListener('updated', onAddedOrChanged);
    };
  }, [service, status, userId, fetchedData]);

  return {
    data: fetchedData,
    ready: status === 'idle',
    error
  };
};

export default useFeathersService;