import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import 'fontsource-roboto';
import { AuthRedirect } from 'features/auth/Auth';
import SignIn from 'features/signIn/SignIn';
import SignUp from 'features/signUp/SignUp';
import Home from 'features/home/Home';
import Wallet from 'features/wallet/Wallet';
import Games from 'features/games/Games';
import Supports from 'features/supports/Supports';
import TxnLogs from 'features/txnLogs/TxnLogs';
import Referral from 'features/referral/Referral';
import Settings from 'features/settings/Settings';
import BalanceProvider from 'features/balanceContext/BalanceProvider';
import UserProvider from 'features/userContext/UserProvider';
import ContentProvider from 'features/contentContext/ContentProvider';
import PwaPrompt from 'features/pwaPrompt/PwaPrompt';

export default function App (props) {
  return (
    <Router>
      <PwaPrompt />
      <AuthRedirect />
        <Switch>
          <Route path='/signin'>
            <SignIn />
          </Route>
          <Route path='/signup/:referral?'>
            <SignUp />
          </Route>
          <ContentProvider>
            <BalanceProvider>
              <UserProvider>
                <Route path='/home'>
                  <Home />
                </Route>
                <Route path='/wallet'>
                  <Wallet />
                </Route>
                <Route path='/games'>
                  <Games />
                </Route>
                <Route path='/supports'>
                  <Supports />
                </Route>
                <Route path='/txnlogs'>
                  <TxnLogs />
                </Route>
                <Route path='/referral'>
                  <Referral />
                </Route>
                <Route path='/settings'>
                  <Settings />
                </Route>
              </UserProvider>
            </BalanceProvider>
          </ContentProvider>
        </Switch>
    </Router>
  );
};
