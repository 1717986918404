import React, { useContext, useEffect, useState } from 'react';
import Layout from 'features/layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { orderBy, take } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import ContentContext from 'features/contentContext/contentContext';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert';
import { grey, red } from '@material-ui/core/colors';
import TxnLogItem from './TxnLogItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    //paddingTop: 0,
    //paddingBottom: 0
  },
  grey: {
    color: theme.palette.getContrastText(grey[200]),
    backgroundColor: grey[200],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
}));

export default function TxnLogs () {
  const classes = useStyles();
  const { t } = useTranslation();
  const { dp, dpReady, dpError, wd, wdReady, wdError } = useContext(ContentContext);
  const [ message, setMessage ] = useState('');
  const lastTen = getLastTenList();

  useEffect(() => {
    if (!dpError) return;
    setMessage(dpError.message);
  }, [dpError]);

  useEffect(() => {
    if (!wdError) return;
    setMessage(wdError.message);
  }, [wdError]);

  const isReady = () => {
    return dpReady && wdReady;
  };

  function getLastTenList() {
    const merged = [ ...dp, ...wd ];
    const ordered = orderBy(merged, ['createdAt'], ['desc']);
    const lastTen = take(ordered, 10);
    return lastTen;
  };

  const content = (
    <Grid container spacing={3} justify='center'>
      <Loader open={!isReady()}/>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
        {
          lastTen.length ?
          <List className={classes.root}>
            {
              lastTen.map((txn, index) => {
                return <TxnLogItem key={txn._id} txn={txn} isLast={index === (lastTen.length - 1)}/>
              })
            }
          </List> :
          <Alert severity='info'>{t(`No record`)}</Alert>
        }
      </Grid>
      <SnackbarMessage id='txnlogs-snackbar' message={message} setMessage={setMessage} />
    </Grid>
  );


  return (
    <Layout content={content} pageTitle={t(`Latest Transactions`)} />
  );
};
