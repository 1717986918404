import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import useLocaleLookup from 'hooks/useLocaleLookup';
import feathers from 'services/feathers';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import { getLocaleId, getName, getUserId } from 'features/user/userSelectors';
import { updateNameAction, updateLocaleIdAction } from 'features/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import Loader from 'features/loader/Loader';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    textAlign: 'center'
  },
  formControlLabel: {
    textAlign:  'left'
  }
}));

export default function BankFormCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const cachedLocale = useSelector(getLocaleId);
  const cachedName = useSelector(getName);
  const userId = useSelector(getUserId);
  const { locales } = useLocaleLookup();
  const [ locale, setLocale ] = useState(null);
  const [ name, setName ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ status, setStatus ] = useState('idle');
  const [ message, setMessage ] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);

  useEffect(() => {
    if (!cachedLocale) return;
    setLocale(cachedLocale);
  }, [cachedLocale]);

  useEffect(() => {
    if (!cachedName) return;
    setName(cachedName);
  }, [cachedName]);

  useEffect(() => {
    if (!locale) return;
    const findLocale = find(locales, l => {
      return l._id === locale
    });
    if (!findLocale) return;
    i18n.changeLanguage(findLocale.code);
  }, [locale, i18n, locales]);

  const handleLocaleChange = (event) => {
    event.preventDefault();
    setLocale(event.target.value);
  };

  const isFormUnchanged = () => {
    return newPassword === '' && locale === cachedLocale && name === cachedName;
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (isFormUnchanged()) return;
    setStatus('saving');
    try {
      let data = {
        name,
        locale
      };
      if (newPassword) data['password'] = newPassword;

      const savedUser = await feathers.service('/users').update(userId, data);

      // Sync local state
      dispatch(updateNameAction(savedUser.name));
      dispatch(updateLocaleIdAction(savedUser.locale));

      // Cleanup after update
      setNewPassword('');
    } catch (err) {
      setMessage(err.message)
    }
    setStatus('idle');
  };

  const onTextChange = (event, setter, oldValue) => {
    event.preventDefault();
    if (event.target.validity.valid || event.target.value === '') setter(event.target.value);
    else setter(oldValue);
  };

  const isFormReady = () => {
    return locale && name;
  };

  const isIdle = () => {
    return status === 'idle';
  };

  return (
    <div>
      <Loader open={!isIdle()}/>
      <Card className={classes.root}>
        <form noValidate autoComplete='off'>
          <CardContent>
            <Typography variant='h6' display='block' gutterBottom>
              {t(`Personal config`)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='locale-select'>{t(`Locale`)}</InputLabel>
                  <Select
                    native
                    value={locale || ''}
                    onChange={handleLocaleChange}
                    label={t(`Locale`)}
                    inputProps={{
                      name: 'locale',
                      id: 'locale-select',
                    }}
                    autoFocus
                  >
                    <option aria-label='None' value='' />
                    {
                      locales.map(locale => {
                        return <option key={locale._id} value={locale._id}>{locale.name}</option>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='name'
                  variant='outlined'
                  required
                  fullWidth
                  id='name'
                  label={t(`Full Name`)}
                  value={name}
                  onChange={e => { onTextChange(e, setName, name) }}
                  inputProps={{
                    pattern: '.{0,50}'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='newPassword'
                  variant='outlined'
                  required
                  fullWidth
                  id='newPassword'
                  label={t(`New Password`)}
                  value={newPassword}
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => { onTextChange(e, setNewPassword, newPassword) }}
                  inputProps={{
                    pattern: '.{0,64}'
                  }}
                  InputProps={{
                    endAdornment:
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={ () => setShowPassword(!showPassword) }
                        onMouseDown={ e => e.preventDefault() }
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type='submit' onClick={handleSave} variant='contained' size='large' color='primary' disabled={!isFormReady()}>{t(`Save`)}</Button>
          </CardActions>
        </form>
      </Card>
      <SnackbarMessage id='home-snackbar' message={message} setMessage={setMessage} />
    </div>
  );
}
