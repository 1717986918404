import React, { useContext, useEffect, useState } from 'react';
import Layout from 'features/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import BalanceCard from 'features/wallet/BalanceCard';
import UserContext from 'features/userContext/userContext';
import BankCardContent from 'features/wallet/BankCardContent';
import BankFormCard from 'features/wallet/BankFormCard';
import DepositDialog from 'features/transactions/DepositDialog';
import DepositPostDialog from 'features/transactions/DepositPostDialog';
import WithdrawalDialog from 'features/transactions/WithdrawalDialog';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Alert from 'features/alert/Alert';
import { find } from 'lodash';

export default function Wallet() {
  const { t } = useTranslation();
  const [ message, setMessage ] = useState('');
  const { dp, wd, bankAccount, ready: userContextReady } = useContext(UserContext);
  const [ openAlert, setOpenAlert ] = useState(false);
  const [ alertDescription, setAlertDescription ] = useState('');
  const [ openPermissionDialog, setOpenPermissionDialog ] = useState(false);
  const [ openDepositDialog, setOpenDepositDialog ] = useState(false);
  const [ openDepositPostDialog, setOpenDepositPostDialog ] = useState(false);
  const [ openWithdrawalDialog, setOpenWithdrawalDialog ] = useState(false);

  useEffect(() => {
    if (!('Notification' in window)) return;
    if (Notification.permission === 'default') setOpenPermissionDialog(true);
  }, []);

  useEffect(() => {
    if (dp.length <= 0) setOpenDepositPostDialog(false);
    else {
      const pendingDeposit = find(dp, d => {
        return ((d.status === 'pending' || d.status === 'processing') && !d.proofOfTransfer)
      });
      if (pendingDeposit) setOpenDepositPostDialog(true);
    }
  }, [dp]);

  const isBusy = () => {
    return !userContextReady;
  };

  const handlePermissionCheck = () => {
    setOpenPermissionDialog(false);
    Notification.requestPermission();
  };

  const handleTxnClick = (event, type) => {
    event.preventDefault();

    if (!bankAccount) {
      setOpenAlert(true);
      setAlertDescription(t(`Kindly update your bank account`));
      return;
    }

    switch (type) {
      // deposit clicked
      case 'dp':
        if (dp.length > 0) {
          setOpenDepositPostDialog(true);
        } else if (wd.length > 0) {
          setOpenAlert(true);
          setAlertDescription(t(`No deposit as withdrawal pending`));
        } else {
          setOpenDepositDialog(true);
        }
        break;
      // withdrawal clicked
      case 'wd':
        if (dp.length > 0) {
          setOpenAlert(true);
          setAlertDescription(t(`No withdrawal as deposit pending`));
        } else if (wd.length > 0) {
          setOpenAlert(true);
          setAlertDescription(t(`Withdrawal in progress`));
        } else {
          setOpenWithdrawalDialog(true);
        };
        break;
      default:
    };
  }

  const content = (
    <div>
     <Dialog
        open={openPermissionDialog}
        onClose={handlePermissionCheck}
      >
        <DialogTitle>{t(`PN Permission Title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText >
            {t(`PN Permission Body 1`)}
          </DialogContentText>
          <DialogContentText >
            {t(`PN Permission Body 2`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePermissionCheck} color="primary">
            {t(`No`)}
          </Button>
          <Button onClick={handlePermissionCheck}>
            {t(`Ok`)}
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        closeButtonText={t(`Close`)}
        title={t(`Sorry`)}
        description={alertDescription}
      />
      <Grid container spacing={3} justify='center'>
        <Loader open={isBusy()}/>
        {
          userContextReady && !bankAccount &&
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <BankFormCard />
          </Grid>
        }
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <BalanceCard children={<BankCardContent />} onTxnClick={handleTxnClick} />
        </Grid>
        <SnackbarMessage id='wallet-snackbar' message={message} setMessage={setMessage} />
        <DepositDialog open={openDepositDialog} setOpen={setOpenDepositDialog} />
        <DepositPostDialog open={openDepositPostDialog} setOpen={setOpenDepositPostDialog} />
        <WithdrawalDialog open={openWithdrawalDialog} setOpen={setOpenWithdrawalDialog} />
      </Grid>
    </div>
  );

  return (
    <Layout content={content} pageTitle={t(`Wallet`)} />
  );
};
