import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { get, find } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import UserContext from 'features/userContext/userContext';
import axiosClient from 'services/axios';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// axios cancel token factory
const CancelToken = axios.CancelToken;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  input: {
    display: 'none',
  },
  amount: {
    fontSize: 24
  },
  listRoot: {
    '& > .MuiListItem-root': {
      //textAlign: 'center'
    }
  },
  listItemRoot: {
    '& > .MuiListItemText-primary': {
      fontSize: 24
    }
  },
  uploadedRoot: {
    '& > .MuiTypography-overline:last-child': {
      fontSize: 34
    },
    '& > .MuiTypography-overline': {
      textAlign: 'center'
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function DepositPostDialog (props) {
  const classes = useStyles();
  const { open, setOpen, history } = props;
  const { t } = useTranslation();
  const { dp } = useContext(UserContext);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ uploadPercentage, setUploadPercentage ] = useState(0);
  const [ status, setStatus ] = useState('idle');
  const [ source ] = useState(CancelToken.source());
  const [ openSnackbar, setOpenSnackBar ] = useState(false);

  const pendingDeposit = find(dp, d => {
    const isReferral = get(d, 'depositBonus.isReferral', false);
    if (isReferral) return false;
    return d.status === 'pending' || d.status === 'processing'
  });
  const potUploaded = get(pendingDeposit, 'proofOfTransfer', false);
  const amount = get(pendingDeposit, 'amount.$numberDecimal', '0');
  const accountHolder = get(pendingDeposit, 'toAccount.accountHolder', '');
  const accountNumber = get(pendingDeposit, 'toAccount.accountNumber', '');
  const formattedAccountNumber = accountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  const bankName = get(pendingDeposit, 'toAccount.bank.name', '');
  const bonusAmount = get(pendingDeposit, 'depositBonus.amount.$numberDecimal', '0');

  useEffect(() => {
    return () => {
      if (source) source.cancel('Upload cancelled');
    }
  }, [history.location.pathname, source]);

  if (!pendingDeposit) return null;

  const canUpload = () => {
    return status === 'idle';
  };

  const getTitle = () => {
    if (pendingDeposit.status === 'pending') return t(`Deposit With Pending Action`);
    else return t(`Deposit In Progress`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadClick = (event) => {
    var file = event.target.files[0];

    const uploadRef = get(pendingDeposit, '_id');
    const formData = new FormData();
    formData.append('uploadRef', uploadRef);
    formData.append('uri', file, file.name);
    setErrorMessage('');
    setStatus('uploading');
    setUploadPercentage(0);

    function handleUploadProgress (pe) {
      const p = Math.round(pe.loaded / pe.total * 100);
      setUploadPercentage(p);
      if (pe.total === pe.loaded) setStatus('idle');
    };

    axiosClient.post('/uploads-dp', formData, {
      onUploadProgress: handleUploadProgress,
      cancelToken: source.token
    }).catch(err => {
      if (err && err.message === 'Upload cancelled') {
      } else {
        const em = get(err, 'response.data.message');
        setStatus('idle');
        setErrorMessage(em);
      }
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCopy = (event) => {
    setOpenSnackBar(true);
  };

  const generatePaidDepositDialog = () => {
    return (
      <div>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List className={classes.listRoot}>
                <ListItem divider>
                  <Typography variant='body1' display='block' gutterBottom>
                  {t(`Kindly deposit p1`)}
                  <span>
                    <span className={classes.amount}>
                      {amount}
                    </span>
                    {t(`Kindly deposit p2`)}
                  </span>
                </Typography>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary={accountHolder} secondary={bankName} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.listItemRoot} primary={formattedAccountNumber} />
                  <CopyToClipboard text={accountNumber} onCopy={handleOnCopy}>
                    <IconButton aria-label='copy' className={classes.margin}>
                      <FileCopyIcon fontSize='small' />
                    </IconButton>
                  </CopyToClipboard>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
          {
           !potUploaded ?
            <Grid item xs={12}>
              <input
                accept='image/*'
                className={classes.input}
                id='contained-button-file'
                multiple={false}
                onChange={handleUploadClick}
                type='file'
                disabled={!canUpload()}
              />
              <label htmlFor='contained-button-file'>
                <Button variant='contained' fullWidth size='large' color='primary' component='span' disabled={!canUpload()} startIcon={<CloudUploadIcon />}>
                  {t(`Upload Receipt`)}
                </Button>
              </label>
            </Grid> :
            <Grid item xs={12} className={classes.uploadedRoot}>
              <Typography variant='overline' display='block' gutterBottom>
                {t(`Receipt uploaded p1`)}
              </Typography>
              <Typography variant='overline' display='block' gutterBottom>
                {t(`Receipt uploaded p2`)}
              </Typography>
              <Typography variant='overline' display='block' gutterBottom>
                <span role='img' aria-label='thank-you-smiley'>
                  &#128515;
                </span>
              </Typography>
            </Grid>
          }
          {
            (status === 'uploading') &&
            <Grid item xs={12}>
               <LinearProgress variant='determinate' value={uploadPercentage} />
            </Grid>
          }
          {
            errorMessage &&
            <Grid item xs={12}>
              <Alert severity='error'>{errorMessage}</Alert>
            </Grid>
          }
          </Grid>
        </DialogActions>
      </div>
    );
  };

  const generateFreeDepositDialog = () => {
    return (
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Typography variant='body1' display='block' gutterBottom>
              {t(`Free credit p1`)}
              <span>
                <span className={classes.amount}>
                  {bonusAmount}
                </span>
                {t(`Free credit p2`)}
              </span>
            </Typography>

          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  return (
    <div>
      <form noValidate autoComplete='off'>
        <Dialog onClose={handleClose} aria-labelledby='deposit-post-dialog-title' open={open} fullWidth>
          <DialogTitle id='deposit-post-dialog-title' onClose={handleClose}>
            {getTitle()}
          </DialogTitle>
          {
            amount > 0 ?
              generatePaidDepositDialog() :
              generateFreeDepositDialog()
          }
        </Dialog>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={t(`Account Number Copied`)}
      />
    </div>
  );
}

DepositPostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default withRouter(DepositPostDialog);
