import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { AbilityContext } from './casl/ability-context';
import ability from './casl/ability';
import { reAuthenticateAsync } from 'features/auth/authActions';
import { ThemeProvider } from '@material-ui/core/styles';
import customTheme from './themes/singaporeRed';
import './i18n';

if (localStorage['feathers-jwt']) store.dispatch(reAuthenticateAsync());

// change to React.Fragment from React.StrictMode for mui...
ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <AbilityContext.Provider value={ability}>
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      </AbilityContext.Provider>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
