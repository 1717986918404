import React, { useContext, useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import UserContext from 'features/userContext/userContext';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { formatBankAccountNumber, maskBankAccountNumber } from 'utils/regex';
import { get } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function BankCardContent(props) {
  const { t } = useTranslation();
  const { bankAccount } = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const [ showAccNum, setShowAccNum ] = useState(false);


  if (!bankAccount) return null;

  const accountNumber = get(bankAccount, 'accountNumber');
  const formattedAccountNumber = formatBankAccountNumber(accountNumber);
  const maskedAccountNumber = maskBankAccountNumber(accountNumber);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <CardActions>
        <Button onClick={handleExpandClick} color='primary'>{t(`View my bank account`)}</Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          <Typography variant='h6' component='h2'>
            {bankAccount.accountHolder.toUpperCase()}
          </Typography>
          <Typography variant='subtitle1' component='h2'>
            {bankAccount.bank.name.toUpperCase()}
          </Typography>
          <Typography variant='subtitle2' component='h2' gutterBottom>
            {showAccNum ? formattedAccountNumber : maskedAccountNumber}
            <span>
              <IconButton
                aria-label='toggle account number visibility'
                onClick={ () => setShowAccNum(!showAccNum) }
                onMouseDown={ e => e.preventDefault() }
              >
                {showAccNum ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </span>
          </Typography>
          <Typography variant="overline" display="block">
            {t(`(Amendment can be made via customer service)`)}
          </Typography>
        </CardContent>
      </Collapse>
    </div>
  );
}
