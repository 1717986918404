import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import { find, get } from 'lodash';
import axiosClient from 'services/axios/anonymous';

const useStyles = makeStyles((theme) => ({
  langFormControl: {
    margin: theme.spacing(1),
    '& > .MuiInput-root': {
      minWidth: 120
    }
  },
}));

function useLocaleSelect (modelName, serviceName) {
  const { i18n, ready } = useTranslation();
  const [ locale, setLocale ] = useState(i18n.language || 'en');
  const [ localeId, setLocaleId ] = useState(null);
  const [ status, setStatus ] = useState('loadTranslation');
  const [ localeLookup, setLocaleLookup ] = useState([{ code: 'en', name: 'English' }]);
  const [ message, setMessage ] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (!ready || status !== 'loadTranslation') return;
    setStatus('loadLocales');
  }, [ready, status]);

  useEffect(() => {
    if (status !== 'loadLocales') return;
    let isMounted = true;
    async function fetchLocales () {
      try {
        const locales = await axiosClient.get('/locales?$sort[name]=1');
        if (isMounted) setLocaleLookup(locales.data.data);
      } catch (err) {
        const errorMessage = get(err, 'response.data.message');
        setMessage(`Failed to fetch locale. (${errorMessage})`);
      }
      if (isMounted) setStatus('idle');
    };
    fetchLocales();

    return () => {
      isMounted = false;
    };
  }, [status]);

  useEffect(() => {
    if (status !== 'idle') return;
    i18n.changeLanguage(locale);
    const findLocale = find(localeLookup, { code: locale });
    if (findLocale) setLocaleId(findLocale._id);
  }, [locale, status, i18n, localeLookup]);

  const handleLocaleChange = (event) => {
    setLocale(event.target.value);
  };

  const generateLocaleSelect = () => {
    return (
      <div>
        <FormControl className={classes.langFormControl}>
          <InputLabel htmlFor='lang-native'>Language</InputLabel>
          <NativeSelect
            inputProps={{
              name: 'lang',
              id: 'lang-native',
            }}
            value={locale}
            onChange={handleLocaleChange}
          >
            {
              localeLookup.map(l => {
                return <option key={l.code} value={l.code}>{l.name}</option>
              })
            }
          </NativeSelect>
        </FormControl>
        <SnackbarMessage id='locale-sel-snackbar' message={message} setMessage={setMessage} />
      </div>
    );
  };

  return {
    localeSelectReady: status === 'idle',
    generateLocaleSelect,
    localeId
  }
};

export default useLocaleSelect;