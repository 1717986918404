import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import { getUserId } from 'features/user/userSelectors';

function useBankTypeLookup () {
  const userId = useSelector(getUserId);
  const service = feathers.service(`/banks`);
  const [ bankTypes, setBankTypes ] = useState([]);
  const [ status, setStatus ] = useState('fetch');

  useEffect(() => {
    setBankTypes([]);
    setStatus('fetch');
  }, [userId])

  useEffect(() => {
    let isMounted = true;
    if (!userId || status !== 'fetch') return;
    async function fetch () {
      try {
        const find = await service.find({ query: { $sort: { name: 1 } }});
        if (isMounted) setBankTypes(find.data);
      } catch (err) {} // Ignore error
      if (isMounted) setStatus('idle');
    };
    fetch();

    return () => {
      isMounted = false;
    };
  }, [userId, service, status]);

  return {
    bankTypes,
    ready: status === 'idle'
  }
};

export default useBankTypeLookup;