import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get } from 'lodash';

export default function AlertDialog(props) {
  const open = get(props, 'open', false);
  const setOpen = get(props, 'setOpen');
  const title = get(props, 'title', '');
  const description = get(props, 'description', '');
  const closeButtonText = get(props, 'closeButtonText', '');

  const handleOnClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            { description }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color='primary' autoFocus>
            {closeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeButtonText: PropTypes.string.isRequired,
};