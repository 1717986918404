import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import feathers from 'services/feathers';
import Alert from '@material-ui/lab/Alert';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function WithdrawalDialog (props) {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const [ amount, setAmount ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleAmountChange = (event) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    if (!amount) return;

    const data = {
      amount: toNumber(amount)
    };

    try {
      await feathers.service('/withdrawal-logs').create(data);
      setOpen(false);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <div>
      <form noValidate autoComplete='off' onSubmit={ (e) => { e.preventDefault(); }}>
        <Dialog onClose={handleClose} aria-labelledby='withdrawal-dialog-title' open={open} fullWidth>
          <DialogTitle id='withdrawal-dialog-title' onClose={handleClose}>
            {t(`Withdrawal`)}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name='amount'
                  variant='outlined'
                  required
                  fullWidth
                  id='amount'
                  label={t(`Amount`)}
                  type='number'
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Grid>
              {
                errorMessage &&
                <Grid item xs={12}>
                  <Alert severity='error'>{errorMessage}</Alert>
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type='submit' onClick={handleSubmit} color='primary'>
              {t(`Submit`)}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}


WithdrawalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
