import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useImageDownloader from 'hooks/useImageDownloader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  potPaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1.5)
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  pot: {
    width: '100%'
  }
}));

export default function PotView ({ potId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { image: pot, ready: potReady } = useImageDownloader(potId, 'proof-of-transfers');

  return (
    <div>
      {
        !potReady ? <LinearProgress /> :
        <Paper elevation={2} className={classes.potPaper}>
          <Typography variant="h6" gutterBottom>
            {t(`Pot`)}
          </Typography>
          <Divider light className={classes.divider} />
          <img className={classes.pot} alt={`pot-${potId}`} src={pot} />
        </Paper>
      }
    </div>
  );
};

PotView.propTypes = {
  potId: PropTypes.string.isRequired,
};

