import React, { useState, useEffect, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { checkSupport, getDeviceType } from 'utils/device';
import CONSTANTS from './constants';
import useFeathersService from 'hooks/useFeathersService';
import LinearProgress from '@material-ui/core/LinearProgress';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import feathers from 'services/feathers';
import BalanceContext from 'features/balanceContext/balanceContext';
import dayjs from 'dayjs';
import reducer, { initialState } from './gameControlReducer';

const useStyles = makeStyles((theme) => ({
  dividerRoot: {
    maxWidth: theme.spacing(2),
    '& > .MuiDivider-root': {
      margin: 'auto'
    }
  },
  warningRoot: {
    textAlign: 'center'
  }
}));

export default function GameControls ({ productInfo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ message, setMessage ] = useState('');
  const [ gameUrl, setGameUrl ] = useState('');
  const { balance: walletBalance } = useContext(BalanceContext);

  const {
    data: acc,
    ready: accChecked,
    error: accCheckError
  } = useFeathersService(CONSTANTS.extUserService, { query: { $limit: 1 } });

  const iosReady = get(productInfo, 'iosReady', false);
  const androidReady = get(productInfo, 'androidReady', false);
  const isSupported = checkSupport({ androidReady, iosReady });
  const deviceType = getDeviceType();

  useEffect(() => {
    if (!acc.length) return;
    dispatch({ type: 'UPDATE_CREDENTIAL', data: acc[0] });
  }, [acc]);

  useEffect(() => {
    let isMounted = true;
    if (!state.lastLogin || !state.credential) return;
    async function start () {
      const params = {
        query: {
          method: 'login'
        }
      };
      const username = get(state.credential, 'username');
      const getLink = await feathers.service(CONSTANTS.bridgeService).get(username, params);
      if (isMounted) setGameUrl(getLink.link);
    };
    start();
    return () => {
      isMounted = false;
    };
  }, [state.lastLogin, state.credential]);

  useEffect(() => {
    let isMounted = true;
    if (!state.lastDemo || !state.credential) return;
    async function startDemo () {
      const params = {
        query: {
          method: 'demoLogin'
        }
      };
      const username = get(state.credential, 'username');
      const getLink = await feathers.service(CONSTANTS.bridgeService).get(username, params);
      if (isMounted) setGameUrl(getLink.link);
    };
    startDemo();
    return () => {
      isMounted = false;
    };
  }, [state.lastDemo, state.credential]);

  useEffect(() => {
    if (!gameUrl) return;
    window.open(gameUrl, '_blank');
  }, [gameUrl]);

  useEffect(() => {
    if (!accCheckError) return;
    setMessage(t(`Failed to check credential`));
  }, [accCheckError, t]);

  async function handleActivateAndEnter (event) {
    if (event) event.preventDefault();
    try {
      await feathers.service(CONSTANTS.extUserService).create({});
      handleEnter();
    } catch (err) {
      setMessage(err.message);
    }
  };

  async function handleEnter (event) {
    if (event) event.preventDefault();

    if (walletBalance <= 0) {
      setMessage(t(`No balance`));
      return;
    }

    if (!state.lastAction) {
      dispatch({ type: 'TRIGGER_LOGIN' });
      return;
    }

    const now = dayjs();
    const diff = now.diff(dayjs(state.lastAction), 's');
    if (diff < CONSTANTS.actionCd) {
      setMessage(t(`Too frequent`));
    } else {
      dispatch({ type: 'TRIGGER_LOGIN' });
    }
  };

  function handleDemo (event) {
    if (event) event.preventDefault();

    if (!state.lastAction) {
      dispatch({ type: 'TRIGGER_DEMO' });
      return;
    }

    const now = dayjs();
    const diff = now.diff(dayjs(state.lastAction), 's');
    if (diff < CONSTANTS.actionCd) {
      setMessage(t(`Too frequent`));
    } else {
      dispatch({ type: 'TRIGGER_DEMO' });
    }
  };

  function generateEnterButton () {
    if (state.credential === null)
      return (<Button fullWidth size='large' color='primary' variant='contained' onClick={handleActivateAndEnter}>{t(`Enter`)}</Button>);
    else
      return (<Button fullWidth size='large' color='primary' variant='contained' onClick={handleEnter}>{t(`Enter`)}</Button>);
  };

  function generateDemoButton () {
    return (<Button fullWidth size='large' variant='contained' onClick={handleDemo}>{t(`Demo`)}</Button>);
  };

  if (!accChecked) {
    return (<LinearProgress />);
  };

  return (
    <CardActions>
    {
      !isSupported ?
      // Not supproted
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} className={classes.warningRoot}>
          <Typography variant='body2' color='textSecondary' component='p'>
            {t(`No ${deviceType}`)}
          </Typography>
        </Grid>
      </Grid> :

      // Supported
      <Grid container spacing={2} justify='center'>
        <Grid item xs={5}>
          {
            generateDemoButton()
          }
        </Grid>
        <Grid item xs={2} className={classes.dividerRoot}>
          <Divider light orientation='vertical' />
        </Grid>
        <Grid item xs={5}>
          {
            generateEnterButton()
          }
        </Grid>
      </Grid>
    }
    <SnackbarMessage id='home-snackbar' message={message} setMessage={setMessage} />
    </CardActions>
  );
}

GameControls.propTypes = {
  productInfo: PropTypes.object.isRequired,
};
