import BalanceContext from './balanceContext';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import { getUserId } from 'features/user/userSelectors';
import { get, toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

function BalanceProvider (props) {
  const userId = useSelector(getUserId);
  const service = feathers.service(`/wallets`);
  const [ balance, setBalance ] = useState(0);
  const { t } = useTranslation();

  useState(() => {
    setBalance(0);
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    async function fetchBalance () {
      try {
        const w = await service.find({
          query: {
            owner: userId
          }
        });
        if (!w.total) return;
        const b = get(w, 'data[0].balance.$numberDecimal');
        if (b) setBalance(toNumber(b));
      } catch (err) {} // Ignore error
    };
    fetchBalance();
  }, [userId, service]);

  useEffect (() => {
    function notifyBalance(balance) {
      if (!('Notification' in window)) return

      if (Notification.permission === 'granted') {
        showNotification(balance);
        return;
      }

      if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(function (permission) {
          if (permission === 'granted') showNotification(balance);
        });
      }
    };

    function showNotification (balance) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification(t(`PN Balance Title`), {
          body: t(`PN Balance Body`, { amount: balance }),
          icon: '/pwa-192.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: 'balance'
        });
      });
    };

    const onUpdated = (data) => {
      const b = get(data, 'balance.$numberDecimal');
      if (b) setBalance(toNumber(b));
      notifyBalance(b);
    };

    service.on('updated', onUpdated);

    return () => {
      service.removeListener('updated', onUpdated);
    };
  }, [service, t]);

  return (
    <BalanceContext.Provider value={{ balance: balance.toFixed(2) }}>
      {props.children}
    </BalanceContext.Provider>
  );
};

export default BalanceProvider;