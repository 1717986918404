import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    errorMessage: null,
    isAuthenticated: false,
    rules: [],
    status: 'idle',
  },
  reducers: {
    authenticateStart: (state, action) => {
      state.errorMessage = null;
      state.isAuthenticated = false;
      state.status = 'loading';
    },
    authenticateSucceed: (state, action) => {
      state.errorMessage = null;
      state.isAuthenticated = true;
      state.rules = action.payload.rules;
      state.status = 'idle';
    },
    authenticateFailed: (state, action) => {
      state.errorMessage = action.payload.errorMessage;
      state.isAuthenticated = false;
      state.status = 'idle';
    },
    clearErrorMessage: (state, action) => {
      state.errorMessage = null
    },
    logout: (state, action) => {
      state.isAuthenticated = false;
    },
  }
});

export const {
  authenticateStart,
  authenticateSucceed,
  authenticateFailed,
  clearErrorMessage,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
