import React, { useContext, useEffect, useState } from 'react';
import Layout from 'features/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { get, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import NewsCard from 'features/newsCard/NewsCard';
import ContentContext from 'features/contentContext/contentContext';

export default function Home () {
  const { t } = useTranslation();
  const { news, newsReady, newsError } = useContext(ContentContext);
  const [ message, setMessage ] = useState('');

  useEffect(() => {
    if (!newsError) return;
    setMessage(newsError.message);
  }, [newsError]);

  const content = (
    <Grid container spacing={3}>
      <Loader open={!newsReady}/>
      {
        orderBy(news, ['publishDate'], ['desc']).map(n => {
          const isEnabled = get(n, 'isEnabled', false);
          if (!isEnabled) return null;

          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={n._id}>
              <NewsCard news={n} />
            </Grid>
            );
        })
      }
      <SnackbarMessage id='home-snackbar' message={message} setMessage={setMessage} />
    </Grid>
  );


  return (
    <Layout content={content} pageTitle={t(`Home`)} />
  );
};
