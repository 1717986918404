import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import LinearProgress from '@material-ui/core/LinearProgress';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { get } from 'lodash';
import useImageDownloader from 'hooks/useImageDownloader';
import { getName, getUsername } from 'features/user/userSelectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    textAlign: 'center'
  },
  cardActionsRoot: {
    minHeight: theme.spacing(10)
  },
  dividerRoot: {
    maxWidth: theme.spacing(2),
    '& > .MuiDivider-root': {
      margin: 'auto'
    }
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: theme.palette.grey[500],
    marginBottom: '0.875em',
  },
  telegramIcon: {
    color: '#0088cc'
  },
  whatsappIcon: {
    color: '#25d366'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

function SupportCard (props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { support } = props;
  const supportName = get(support, 'name', '');
  const supportTitle = get(support, 'title', '');
  const supportAvatarId = get(support, 'avatar');
  const supportWhatsapp = get(support, 'whatsapp');
  const supportTelegram = get(support, 'telegram');
  const custUsername = useSelector(getUsername);
  const custName = useSelector(getName);
  const { image: logo, ready: logoReady } = useImageDownloader(supportAvatarId);

  const handleTelegram = (event) => {
    event.preventDefault();
    if (!supportTelegram) return;
    const link = `https://t.me/${supportTelegram}`;
    window.location = link;
  };

  const handleWhatsapp = (event) => {
    event.preventDefault();
    if (!supportWhatsapp) return;
    const link = `https://wa.me/${supportWhatsapp}?text=Hi, I am ${custName} with username ${custUsername}`;
    window.location = link;
  };

  return (
    <Card className={classes.root}>
      {
        !logoReady && <LinearProgress />
      }
      <CardContent>
          <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar className={classes.avatar} src={logo} />
        </StyledBadge>
        <h3 className={classes.heading}>{supportName}</h3>
        <span className={classes.subheader}>{t(`${supportTitle}`)}</span>
      </CardContent>
      <Divider light />
      <CardActions className={classes.cardActionsRoot}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={5}>
            <Button
              fullWidth
              size='large'
              variant='contained'
              startIcon={<TelegramIcon className={classes.telegramIcon} />}
              onClick={handleTelegram}>
              {t(`Telegram`)}
            </Button>
          </Grid>
          <Grid item xs={2} className={classes.dividerRoot}>
            <Divider light orientation='vertical' />
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth
              size='large'
              variant='contained'
              endIcon={<WhatsAppIcon className={classes.whatsappIcon} />}
              onClick={handleWhatsapp}>
              {t(`Whatsapp`)}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default SupportCard;

SupportCard.propTypes = {
  support: PropTypes.object.isRequired,
};
