import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function Activation(props) {
  const [open, setOpen] = useState(false);
  const lastActivation = get(props, 'lastActivation');
  //const { activationNumber, username } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!lastActivation) return;
    setOpen(true);
  }, [lastActivation]);

  const handleClose = () => {
    setOpen(false);
  };

  //const handleActivation = () => {
    //const link = `whatsapp://send?text=Activate ${username}&phone=${activationNumber}`;
    /*setTimeout(function () {
      if (isAndroid) window.location = WHATSAPP_ANDROID_LINK;
      else if (isIOS) window.location = WHATSAPP_IOS_LINK;
      else window.location = WHATSAPP_WEB_LINK;
    }, 25);*/
    //const link = `https://wa.me/${activationNumber}?text=Activate ${username}`;
    //window.location = link;
    //setOpen(false);
  //};

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='whatsapp-activation-dialog'
        aria-describedby='whatsapp-activation-description'
      >
        <DialogTitle id='whatsapp-activation-dialog'>{t(`Pending Approval`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id='whatsapp-activation-descriptio'>
            {t(`Pending Approval Message`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t(`Close`)}
          </Button>
  {/*        <Button onClick={handleActivation} color='primary' autoFocus>
            {t(`Activate Now`)}
          </Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}

Activation.propTypes = {
  activationNumber: PropTypes.string.isRequired,
  lastActivation: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};
