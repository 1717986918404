import { useEffect, useState } from 'react';
import feathers from 'services/feathers';

function useImageDownloader (fileId=undefined, defaultService='files') {
  const [status, setStatus] = useState('idle');
  const [image, setImage] = useState(null);
  const service = feathers.service(`/${defaultService}`);
  const isCacheSupported = typeof(Storage) !== 'undefined' ? true : false;

  useEffect (() => {
    if (fileId === undefined || fileId === null) return;
    async function fetchData () {
      setStatus('fetching');
      const storeKey = `@FileStore:${fileId}`;
      try {
        let cacheFile = null;

        if (isCacheSupported) cacheFile = localStorage.getItem(storeKey);

        if (cacheFile) {
          setImage(JSON.parse(cacheFile));
        } else {
          const getFile = await service.get(fileId);
          getFile.data = `data:${getFile.mimeType};base64,${getFile.data}`;
          if (getFile) {
            if (isCacheSupported) await localStorage.setItem(storeKey, JSON.stringify(getFile.data));
            setImage(getFile.data);
          }
        }
        setStatus('idle');
      } catch (err) {
        setImage('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
        setStatus('idle');
      };
    };
    fetchData();
  }, [service, fileId, isCacheSupported]);

  return { image, ready: status === 'idle' };
};

export default useImageDownloader;