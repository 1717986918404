import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Editor } from 'draft-js';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function NewsReader(props) {
  const { open, setOpen, formattedTitle, formattedContent } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        aria-labelledby='news-reader-title'
        aria-describedby='news-reader-description'
      >
        <DialogTitle id='news-reader-title'>{formattedTitle}</DialogTitle>
        <DialogContent dividers={true}>
          {
            formattedContent &&
            <Editor
              editorState={formattedContent}
              readOnly={true}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t(`Close`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

NewsReader.propTypes = {
  formattedTitle: PropTypes.any.isRequired,
  formattedContent: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
