import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Alert from 'features/alert/Alert';
import { useTranslation } from 'react-i18next';
import useBankTypeLookup from 'hooks/useBankTypeLookup';
import feathers from 'services/feathers';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    textAlign: 'center'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControlLabel: {
    textAlign:  'left'
  }
}));

export default function BankFormCard(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ openAlert, setOpenAlert ] = useState(false);
  const [ alertDescription ] = useState('');
  const { bankTypes } = useBankTypeLookup();
  const [ bank, setBank ] = useState('');
  const [ accountHolder, setAccountHolder ] = useState('');
  const [ accountNumber, setAccountNumber ] = useState('');
  const [ agree, setAgree ] = useState(false);
  const [ message, setMessage ] = useState('');

  const handleBankChange = (event) => {
    event.preventDefault();
    setBank(event.target.value);
  };

  const handleAgreementChange = (event) => {
    setAgree(event.target.checked);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      await feathers.service('/customer-bank-accounts').create({
        bank: bank,
        accountHolder: accountHolder,
        accountNumber: accountNumber
      });
    } catch (err) {
      setMessage(err.message);
    }
  };

  const onTextChange = (event, setter, oldValue) => {
    event.preventDefault();
    if (event.target.validity.valid || event.target.value === '') setter(event.target.value);
    else setter(oldValue);
  };

  const isFormReady = () => {
    return (agree && bank && accountHolder && accountNumber);
  };

  return (
    <div>
      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        closeButtonText={t(`Close`)}
        title={t(`Sorry`)}
        description={alertDescription}
      />
      <Card className={classes.root}>
        <form noValidate autoComplete='off'>
          <CardContent>
            <Typography variant='h6' display='block' gutterBottom>
              {t(`Update My Bank Account`)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='bank-select'>{t(`Bank`)}</InputLabel>
                  <Select
                    native
                    value={bank}
                    onChange={handleBankChange}
                    label={t(`Bank`)}
                    inputProps={{
                      name: 'bank',
                      id: 'bank-select',
                    }}
                    autoFocus
                  >
                    <option aria-label='None' value='' />
                    {
                      bankTypes.map(bank => {
                        return <option key={bank._id} value={bank._id}>{bank.name}</option>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='accountHolder'
                  variant='outlined'
                  required
                  fullWidth
                  id='accountHolder'
                  label={t(`Account Holder Name`)}
                  value={accountHolder}
                  onChange={e => { onTextChange(e, setAccountHolder, accountHolder) }}
                  inputProps={{
                    pattern: '.{0,50}'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='accountNumber'
                  variant='outlined'
                  required
                  fullWidth
                  id='accountNumber'
                  label={t(`Account Number`)}
                  type='number'
                  value={accountNumber}
                  onChange={e => { onTextChange(e, setAccountNumber, accountNumber) }}
                  inputProps={{
                    pattern: '[0-9]*'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={<Checkbox checked={agree} onChange={handleAgreementChange} color='primary' />}
                  label={t(`Agree on withdrawal account`)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type='submit' onClick={handleSave} variant='contained' size='large' color='primary' disabled={!isFormReady()}>{t(`Save`)}</Button>
          </CardActions>
        </form>
      </Card>
      <SnackbarMessage id='home-snackbar' message={message} setMessage={setMessage} />
    </div>
  );
}
