import React, { useEffect, useState, useContext } from 'react';
import Layout from 'features/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import ContentContext from 'features/contentContext/contentContext';
import SupportCard from 'features/supports/SupportCard';

export default function Games () {
  const { t } = useTranslation();
  const [ message, setMessage ] = useState('');
  const { supports, supportsReady, supportsError } = useContext(ContentContext);

  useEffect(() => {
    if (!supportsError) return;
    setMessage(supportsError.message);
  }, [supportsError]);

  const content = (
    <Grid container spacing={3} justify='center'>
      <Loader open={!supportsReady}/>
      {
        sortBy(supports, ['name']).map(support => {
          if (!support.isEnabled) return null;
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={support._id}>
              <SupportCard support={support} />
            </Grid>
          );
        })
      }
      <SnackbarMessage id='support-snackbar' message={message} setMessage={setMessage} />
    </Grid>
  );


  return (
    <Layout content={content} pageTitle={t(`Supports`)} />
  );
};
