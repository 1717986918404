import { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { get } from 'lodash';

function useExternalBtlService (serviceName) {
  const service = feathers.service(`/${serviceName}`);
  const [ lastBtlLog, setLastBtlLog ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    const onAddedOrChanged = (data) => {
      const errorMessage = get(data, 'errorMessage');
      if (errorMessage) setErrorMessage(errorMessage);
      else setLastBtlLog(data);
    };

    service.on('created', onAddedOrChanged);
    service.on('updated', onAddedOrChanged);

    return () => {
      service.removeListener('created', onAddedOrChanged);
      service.removeListener('updated', onAddedOrChanged);
    };
  }, [service]);

  return {
    service,
    lastBtlLog,
    errorMessage
  };
};

export default useExternalBtlService;