import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import useImageDownloader from 'hooks/useImageDownloader';
import { get } from 'lodash';
import dayjs from 'dayjs';
import NewsReader from './NewsReader';
import { convertFromRaw, EditorState } from 'draft-js';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  chipRoot: {
    borderRadius: 3,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  }
}));

export default function NewsCard(props) {
  const { news } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const imageId = get(news, 'image');
  const { image, ready: imageReady } = useImageDownloader(imageId);
  const [ openContent, setOpenContent ] = useState(false);
  const [ formattedContent, setFormattedContent ] = useState('');
  const [ plainContent, setPlainContent ] = useState('');

  const publishDate = get(news, 'publishDate', Date.now());
  const formattedDate = dayjs(publishDate).format('YYYY-MM-DD HH:mm');
  const rawTitle = get(news, 'title', '');
  const rawContent = get(news, 'content', '');
  const { title, tag } = extractTagAndTitleFromRaw(rawTitle);

  useEffect(() => {
    if (formattedContent) return;
    if (rawContent) {
      const content = convertFromRaw(JSON.parse(rawContent));
      const fc = EditorState.createWithContent(content);
      const pc = fc.getCurrentContent().getPlainText('\u0001');
      setFormattedContent(fc);
      setPlainContent(`${pc.substring(0, 100)} ...`);
    } else {
      setFormattedContent(EditorState.createEmpty());
    }
  }, [formattedContent, rawContent]);

  function extractTagAndTitleFromRaw (rawTitle) {
    // regex for title tag extraction
    const r = /(\()(.+)(\)) (.+)/g;
    const match = r.exec(rawTitle);
    if (!match) {
      return {
        title: rawTitle,
        tag: ''
      }
    } else {
      return {
        title: match[4],
        tag: match[2]
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setOpenContent(true);
  };

  return (
    <div>
      <NewsReader open={openContent} setOpen={setOpenContent} formattedTitle={title} formattedContent={formattedContent} />
      <Card className={classes.root}>
        {
          !imageReady && <LinearProgress />
        }
        <CardActionArea onClick={handleClick}>
          <CardMedia
            component='img'
            alt={title}
            width='100%'
            image={image}
            title={title}
          />
          <CardContent>
            <Typography variant='overline' display='block' gutterBottom>
              {formattedDate}
            </Typography>
            {
              tag && <Chip className={classes.chipRoot} size='small' color='primary' label={t(`${tag}`)} />
            }
            <Typography gutterBottom variant='h5' component='h2'>
              {title}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
            {plainContent}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

NewsCard.propTypes = {
  news: PropTypes.object.isRequired,
};