import {
  initUser,
  updateName,
  updateLocaleId
} from './userSlice';
import { get } from 'lodash';

export const initUserAction = user => dispatch => {
  const _id = get(user, '_id');
  const username = get(user, 'username');
  const name = get(user, 'name');
  const localeId = get(user, 'locale._id');
  const affiliateTag = get(user, 'affiliateTag');
  dispatch(initUser({ _id, username, name, localeId, affiliateTag }));
};

export const updateNameAction = name => dispatch => {
  dispatch(updateName(name));
};

export const updateLocaleIdAction = localeId => dispatch => {
  dispatch(updateLocaleId(localeId));
};
