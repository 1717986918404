import React from 'react';
import Layout from 'features/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import SettingsCard from 'features/settings/SettingsCard';

export default function Settings() {
  const { t } = useTranslation();

  const content = (
    <div>
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <SettingsCard />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Layout content={content} pageTitle={t(`Settings`)} />
  );
};
