import {
  isIOS,
  isAndroid
} from 'react-device-detect';

export const getDeviceType = () => {
  if (isIOS) return 'ios';
  if (isAndroid) return 'android';
  return 'web';
};

export const checkMobileSupport = ({ androidReady, iosReady }) => {
  if (isIOS && iosReady) return true;
  if (isAndroid && androidReady) return true;
  return false;
};

export const checkSupport = ({ androidReady, iosReady }) => {
  if (isIOS && !iosReady) return false;
  if (isAndroid && !androidReady) return false;
  return true;
};
