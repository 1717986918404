import React, { useEffect, useState, useContext } from 'react';
import Layout from 'features/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { get, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import GameCardKiss from 'features/games/kiss/GameCard';
import GameCardCt from 'features/games/ct/GameCard';
import ContentContext from 'features/contentContext/contentContext';

export default function Games () {
  const { t } = useTranslation();
  const [ message, setMessage ] = useState('');
  const { products, productsReady, productsError } = useContext(ContentContext);

  useEffect(() => {
    if (!productsError) return;
    setMessage(productsError.message);
  }, [productsError]);

  const content = (
    <Grid container spacing={3} justify='center'>
      <Loader open={!productsReady}/>
      {
        sortBy(products, ['name']).map(product => {
          if (product.status !== 'enabled') return null;
          const gameType = get(product, 'onApiSettingModel');

          let cardComponent;
          if (gameType === 'apiSettingsKiss918') {
            cardComponent = (<GameCardKiss productInfo={product} />);
          } else if (gameType === 'apiSettingsKing855') {
            cardComponent = (<GameCardCt productInfo={product} />);
          }

          if (!cardComponent) return null
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={product._id}>
              {cardComponent}
            </Grid>
          );
        })
      }
      <SnackbarMessage id='games-snackbar' message={message} setMessage={setMessage} />
    </Grid>
  );


  return (
    <Layout content={content} pageTitle={t(`Games`)} />
  );
};
