import React, { useEffect, useState, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  withRouter,
  useParams
} from 'react-router-dom';

import Copyright from 'features/copyright/Copyright';
import useLocaleSelect from 'hooks/useLocaleSelect';
import useRegionSelect from 'hooks/useRegionSelect';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import { numberOnly } from 'utils/regex';
import Loader from 'features/loader/Loader';
import Logo from 'assets/images/918kiss.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { get } from 'lodash';

const homeLink = (window.location.hostname.indexOf('.wtf') >= 0 ? 'https://918kiss.wtf' : 'https://918kiss.com.sg');
const supportLink = 'https://api.whatsapp.com/send?phone=601153717563&text=Hi%2C+I+am+stuck+in+the+registration+process%2C+please+help.';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: '#fafafa',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

function SignUp(props) {
  const classes = useStyles();
  const params = useParams();
  const { generateLocaleSelect, localeSelectReady, localeId } = useLocaleSelect();
  const { generateRegionSelect, regionSelectReady, regionId, countryCode } = useRegionSelect();
  const { t } = useTranslation();
  const [ showPassword, setShowPassword ] = useState(false);
  const [ fullName, setFullName ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ contact, setContact ] = useState('');
  const [ referralCode, setReferralCode ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ status, setStatus ] = useState('idle');
  const [ token, setToken ] = useState(null);
  const recaptchaRef = useRef();
  const recaptchaSiteKey = process.env.REACT_APP_SITEKEY || null;

  useEffect(() => {
    const rc = get(params, 'referral');
    if (rc === undefined) return;
    setReferralCode(rc);
  }, [params]);

  useEffect(() => {
    let isMounted = true;
    if (status !== 'signUp') return;
    if (!isFormReady()) return setStatus('idle');

    function isFormReady () {
      return (
        localeId &&
        regionId &&
        countryCode &&
        fullName &&
        username &&
        password &&
        contact &&
        token
      );
    };

    async function signUp () {
      try {
        const fullContact = `${countryCode}${contact}`;
        await feathers.service('/users').create({
          token: token,
          locale: localeId,
          region: regionId,
          name: fullName,
          username: username,
          password: password,
          contact: fullContact,
          referralCode: referralCode
        });

        if (isMounted) props.history.push({ pathname: '/signin', state: { autoLogin: true, username, password } });
      } catch (err) {
        if (isMounted) {
          setMessage(err.message);
          setToken(null);
          recaptchaRef.current.reset();
        }
      }
      if (isMounted) setStatus('idle');
    };

    signUp();

    return () => {
      isMounted = false;
    };
  }, [status, contact, countryCode, fullName, localeId, password, props.history, regionId, username, token, referralCode]);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleContactChange = (event) => {
    setContact(numberOnly(event.target.value));
  };

  const handleReferralCodeChange = (event) => {
    setReferralCode(event.target.value);
  };

  const handleSignUpClick = async (event) => {
    if (event) event.preventDefault();
    setStatus('signUp');
  };

  const onTokenChange = (token) => {
    setToken(token);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Loader open={!localeSelectReady || !regionSelectReady} />
      <CssBaseline />
      <div className={classes.paper}>
        <Grid
          container
          direction='row-reverse'
        >
          { generateLocaleSelect() }
          { generateRegionSelect() }
        </Grid>
        <Link href={homeLink} variant='body2'>
          <Avatar className={classes.avatar} src={Logo} />
        </Link>
        <Typography variant='overline' display='block' gutterBottom>
          {t(`Welcome to 918Kiss Singapore`)}
        </Typography>
        <Typography component='h1' variant='h5'>
          {t(`Sign up`)}
        </Typography>
        <form className={classes.form} noValidate autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='fullName'
                variant='outlined'
                required
                fullWidth
                id='fullName'
                label={t(`Full Name`)}
                autoFocus
                value={fullName}
                onChange={handleFullNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='username'
                label={t('Username')}
                name='username'
                value={username}
                onChange={handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                id='password'
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={ () => setShowPassword(!showPassword) }
                        onMouseDown={ e => e.preventDefault() }
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='contact'
                label={t(`Contact`)}
                type='number'
                name='contact'
                value={contact}
                onChange={handleContactChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='referralCode'
                label={t(`Referral Code`)}
                type='text'
                name='referralCode'
                value={referralCode}
                onChange={handleReferralCodeChange}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center', marginTop: 16, marginBottom: 0 }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaSiteKey}
              onChange={onTokenChange}
              style={{ display: 'inline-block' }}
            />
          </div>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            size='large'
            onClick={handleSignUpClick}
          >
            {t(`Sign up`)}
          </Button>
          <Grid container justify='flex-end'>
            <Grid item xs>
              <Link href={`${supportLink}`} variant='body2'>
                {t(`Problem register?`)}
              </Link>
            </Grid>
            <Grid item>
              <Link href='#' variant='body2' onClick={ (event) => {
                event.preventDefault();
                props.history.push({ pathname: '/signin' });
              }}>
                {t(`Already have an account? Sign in`)}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <SnackbarMessage id='sigpup-snackbar' message={message} setMessage={setMessage} />
    </Container>
  );
}

export default withRouter(SignUp);
