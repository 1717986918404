import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authSlice from 'features/auth/authSlice';
import userSlice from 'features/user/userSlice';
import { combineReducers } from 'redux';

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') state = undefined;

  return combineReducers({
    auth: authSlice,
    user: userSlice
  })(state, action);
};

const defaultMiddleware = getDefaultMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: defaultMiddleware,
});
