import React, { useEffect, useState } from 'react';
import Layout from 'features/layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import Loader from 'features/loader/Loader';
import { red } from '@material-ui/core/colors';
import { getAffiliateTag, getUserId } from 'features/user/userSelectors';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import QRCode from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(2),
    //paddingTop: theme.spacing(5),
    //paddingBottom: theme.spacing(5)
    '& > .MuiGrid-item': {
      textAlign: 'center'
    }
  }
}));

export default function TxnLogs () {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ message, setMessage ] = useState('');
  const [ referralLink, setReferralLink ] = useState(null);
  const [ refCnt, setRefCnt ] = useState(0);
  const [ refCommAmt, setRefCommAmt ] = useState(0);
  const [ openSnackbar, setOpenSnackBar ] = useState(false);
  const affiliateTag = useSelector(getAffiliateTag);
  const userId = useSelector(getUserId);

  useEffect(() => {
    let isMounted = true;
    if (!affiliateTag || !userId) return;
    const link = `${window.location.protocol}//${window.location.hostname}/signup/${affiliateTag}`;
    setReferralLink(link);

    async function getStats () {
      const getUser = await feathers.service('users').get(userId);
      const _refCnt = get(getUser, 'stats.refCnt', 0);
      const _refCommAmtRaw = get(getUser, 'stats.refCommAmt.$numberDecimal', '0');
      const _refCommAmt = parseFloat(_refCommAmtRaw);

      if (!isMounted) return;
      setRefCnt(_refCnt);
      setRefCommAmt(_refCommAmt);
    };

    getStats();

    return () => {
      isMounted = false;
    }

  }, [affiliateTag, userId]);

  const isReady = () => {
    return referralLink !== null;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCopy = (event) => {
    setOpenSnackBar(true);
  };

  const content = (
    <div>
      <Grid className={classes.root} container spacing={3} justify='center'>
        <Loader open={!isReady()}/>
        <Grid item xs={12}>
          {
            referralLink &&
            <QRCode value={referralLink} size={256} renderAs='svg' fgColor={red[800]} />
          }
        </Grid>
        <Grid item xs={12}>
          <Typography variant='overline' gutterBottom>
            { t('Show the QR or') }
          </Typography>
          {
            referralLink &&
            <div>
              <CopyToClipboard
                text={referralLink}
                onCopy={handleOnCopy}
              >
                <Button variant='contained' color='primary'>
                  { t('Copy and share the link')}
                </Button>
              </CopyToClipboard>
            </div>
          }
        </Grid>
      </Grid>

      <Grid className={classes.root} container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            { t('Referee Count', { count: refCnt }) }
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            { t('Referee Bonus Paid', { amount: refCommAmt.toFixed(2) }) }
          </Typography>
        </Grid>
        <SnackbarMessage id='referral-snackbar' message={message} setMessage={setMessage} />
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={t(`Referral Link Copied`)}
      />
    </div>
  );


  return (
    <Layout content={content} pageTitle={t(`Referral Program`)} />
  );
};
