import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    _id: null,
    username: null,
    name: null,
    localeId: null,
    affiliateTag: null
  },
  reducers: {
    initUser: (state, action) => {
      state._id = action.payload._id;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.localeId = action.payload.localeId;
      state.affiliateTag = action.payload.affiliateTag;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateLocaleId: (state, action) => {
      state.localeId = action.payload;
    }
  }
});

export const {
  initUser,
  updateName,
  updateLocaleId
} = userSlice.actions;

export default userSlice.reducer;
