import React, { useState, useEffect, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import {
  isIOS,
  isAndroid,
  osVersion
} from 'react-device-detect';
import { get, template } from 'lodash';
import { checkMobileSupport, getDeviceType } from 'utils/device';
import CONSTANTS from './constants';
import useFeathersService from 'hooks/useFeathersService';
import LinearProgress from '@material-ui/core/LinearProgress';
import SnackbarMessage from 'features/snackbarMessage/SnackbarMessage';
import feathers from 'services/feathers';
import BalanceContext from 'features/balanceContext/balanceContext';
import dayjs from 'dayjs';
import useExternalBtlService from 'hooks/useExternalBtlService';
import reducer, { initialState } from './gameControlReducer';

const useStyles = makeStyles((theme) => ({
  dividerRoot: {
    maxWidth: theme.spacing(2),
    '& > .MuiDivider-root': {
      margin: 'auto'
    }
  },
  warningRoot: {
    textAlign: 'center'
  }
}));

export default function GameControls ({ productInfo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ message, setMessage ] = useState('');
  const { balance: walletBalance } = useContext(BalanceContext);

  const {
    data: acc,
    ready: accChecked,
    error: accCheckError
  } = useFeathersService(CONSTANTS.extUserService, { query: { $limit: 1 } });
  const { lastBtlLog, service: btlService, errorMessage: btlErrorMessage } = useExternalBtlService(CONSTANTS.extBtlService);
  const appUri = generateUri();

  const iosReady = get(productInfo, 'iosReady', false);
  const androidReady = get(productInfo, 'androidReady', false);
  const isSupported = checkMobileSupport({ androidReady, iosReady });
  const deviceType = getDeviceType();

  useEffect(() => {
    if (!acc.length) return;
    dispatch({ type: 'UPDATE_CREDENTIAL', data: acc[0] });
  }, [acc]);

  useEffect(() => {
    let isMounted = true;
    if (!state.credential) return;

    async function checkBalance () {
      try {
        const username = get(state.credential, 'username');
        const ret = await feathers.service(CONSTANTS.bridgeService).get(username, { query: { method: 'balance' }});
        const balance = get(ret, 'balance', 0);
        if (isMounted) {
          dispatch({ type: 'UPDATE_IS_IN_GAME', data: balance > 0 ? true : false });
        }
      } catch (err) {
        if (isMounted) setMessage(err.message);
      }
    };
    checkBalance();
    return () => {
      isMounted = false;
    };
  }, [state.credential]);

  useEffect(() => {
    let isMounted = true;
    if (!state.lastLogin || state.depositAmount <= 0) return;
    async function deposit () {
      try {
        const data = {
          type: 'deposit',
          amount: state.depositAmount
        };
        await btlService.create(data);
      } catch (err) {
        if (isMounted) setMessage(err.message);
      }
    };
    deposit();
    return () => {
      isMounted = false;
    };
  }, [state.lastLogin, state.depositAmount, btlService]);

  useEffect(() => {
    let isMounted = true;
    if (!state.lastLogout) return;
    async function withdrawal () {
      try {
        const username = get(state.credential, 'username');
        const ret = await feathers.service(CONSTANTS.bridgeService).get(username, { query: { method: 'balance' }}, 0);
        const balance = get(ret, 'balance', 0);

        // If in game balance is zero, return
        if (balance <= 0 && isMounted) return dispatch({ type: 'USER_NOT_IN_GAME' });

        const data = {
          type: 'withdrawal',
          amount: balance
        };
        await btlService.create(data);
      } catch (err) {
        if (isMounted) setMessage(err.message);
      }
    };

    withdrawal();

    return () => {
      isMounted = false;
    };
  }, [state.lastLogout, state.credential, btlService]);

  useEffect(() => {
    if (!lastBtlLog) return;
    const status = get(lastBtlLog, 'status');
    if (status !== 'approved') return;
    const type = get(lastBtlLog, 'type');

    if (type === 'deposit') {
      dispatch({ type: 'USER_IN_GAME' });
      if (!appUri) return;

      setTimeout(function () {
        setMessage(t(`Download reminder`));
      }, 25);
      window.location = appUri;
    } else {
      dispatch({ type: 'USER_NOT_IN_GAME' });
    }
  }, [lastBtlLog, appUri, t]);

  useEffect(() => {
    if(!btlErrorMessage) return;
    setMessage(t(`Operation failed`));
  }, [btlErrorMessage, t]);

  useEffect(() => {
    if (!accCheckError) return;
    setMessage(t(`In game balance failed`));
  }, [accCheckError, t]);

  async function handleActivateAndEnter (event) {
    if (event) event.preventDefault();
    try {
      await feathers.service(CONSTANTS.extUserService).create({});
      handleEnter();
    } catch (err) {
      setMessage(err.message);
    }
  };

  async function handleEnter (event) {
    if (event) event.preventDefault();

    // old uri
    // lobbykissandroid://lobbykissandroid?account=<%= username%>&password=<%= password%>

    // new uri
    // lobbykiss://lobbykiss?account=<%= username%>&password=<%= password%>

    //lobbykiss://lobbykiss?account=01234567&password=123'
    /*window.location = 'LobbyKiss64://account=test8874&password=1234';
    return;*/

    if (walletBalance <= 0) {
      setMessage(t(`No balance`));
      return;
    }

    if (!state.lastAction) {
      dispatch({ type: 'TRIGGER_LOGIN', data: walletBalance });
      return;
    }

    const now = dayjs();
    const diff = now.diff(dayjs(state.lastAction), 's');
    if (diff < CONSTANTS.actionCd) {
      setMessage(t(`Too frequent`));
    } else {
      dispatch({ type: 'TRIGGER_LOGIN', data: walletBalance });
    }
  };

  async function handleExit (event) {
    if (event) event.preventDefault();

    if (!state.lastAction) {
      dispatch({ type: 'TRIGGER_LOGOUT' });
      return;
    }

    const now = dayjs();
    const diff = now.diff(dayjs(state.lastAction), 's');
    if (diff < CONSTANTS.actionCd) {
      setMessage(t(`Too frequent`));
    } else {
      dispatch({ type: 'TRIGGER_LOGOUT' });
    }
  };

  function handleAndroidDownload (event) {
    if (event) event.preventDefault();
    const link = get(CONSTANTS, 'download.android');
    if (!link) return;
    window.location = link;
  };

  function generateEnterExitButton () {
    if (state.credential === null)
      return (<Button fullWidth size='large' color='primary' variant='contained' onClick={handleActivateAndEnter}>{t(`Enter`)}</Button>);

    if (state.isInGame)
      return (<Button fullWidth size='large' color='primary' variant='contained' onClick={handleExit}>{t(`Exit`)}</Button>);
    else
      return (<Button fullWidth size='large' color='primary' variant='contained' onClick={handleEnter}>{t(`Enter`)}</Button>);
  };

  function generateDownloadButton () {
    if (isAndroid) {
      return (
        <Button fullWidth size='large' variant='contained' onClick={handleAndroidDownload}>{t(`Download`)}</Button>
      );
    } else if (isIOS) {
      const link =  get(CONSTANTS, 'download.ios');
      if (!link) return null;
      return (
        <a href={link} rel='noopener noreferrer' target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <Button fullWidth size='large' variant='contained'>{t(`Download`)}</Button>
        </a>
      );
    } else {
      const link = get(CONSTANTS, 'download.default');
      return (
        <a href={link} rel='noopener noreferrer' target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <Button fullWidth size='large' variant='contained'>{t(`Download`)}</Button>
        </a>
      );
    }
  };

  function generateUri () {
    if (!isAndroid && !isIOS) return '';
    let compiled;

    if (isAndroid) {
      compiled = template(CONSTANTS.uri.android);
    } else if (isIOS && osVersion >= 11) {
      compiled = template(CONSTANTS.uri.ios64);
    } else {
      compiled = template(CONSTANTS.uri.ios32);
    }
    const username = get(state.credential, 'username');
    const password = get(state.credential, 'password');
    if (!username || !password) return '';
    return compiled({ username, password });
  };

  if (!accChecked) {
    return (<LinearProgress />);
  };

  return (
    <CardActions>
    {
      !isSupported ?
      // Not supproted
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} className={classes.warningRoot}>
          <Typography variant='body2' color='textSecondary' component='p'>
            {t(`No ${deviceType}`)}
          </Typography>
        </Grid>
      </Grid> :

      // Supported
      <Grid container spacing={2} justify='center'>
        <Grid item xs={5}>
          {
            generateDownloadButton()
          }
        </Grid>
        <Grid item xs={2} className={classes.dividerRoot}>
          <Divider light orientation='vertical' />
        </Grid>
        <Grid item xs={5}>
          {
            generateEnterExitButton()
          }
        </Grid>
      </Grid>
    }
    <SnackbarMessage id='home-snackbar' message={message} setMessage={setMessage} />
    </CardActions>
  );
}

GameControls.propTypes = {
  productInfo: PropTypes.object.isRequired,
};
