export const numberOnly = (value) => {
  return value.replace(/[^0-9]/g, '');
};

export const formatBankAccountNumber = (value) => {
  return value.replace(/(.{4})/g, '$1 ');
};

export const maskBankAccountNumber = (value) => {
  const masked = value.replace(/\b(?:\d{4}){1}/gm, '****');
  return formatBankAccountNumber(masked);
};
