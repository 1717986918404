import ContentContext from './contentContext';
import React from 'react';
import useFeathersService from 'hooks/useFeathersService';

function ContentProvider (props) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

  const { data: news, ready: newsReady, error: newsError } = useFeathersService('news', queryFetchAll);
  const { data: products, ready: productsReady, error: productsError } = useFeathersService('products', queryFetchAll);
  const { data: supports, ready: supportsReady, error: supportsError } = useFeathersService('supports', queryFetchAll);

  const queryFetchPart = {
    query: {
      $limit: 10,
      $sort: {
        createdAt: -1
      }
    }
  };
  const { data: dp, ready: dpReady, error: dpError } = useFeathersService('deposit-logs', queryFetchPart);
  const { data: wd, ready: wdReady, error: wdError } = useFeathersService('withdrawal-logs', queryFetchPart);

  return (
    <ContentContext.Provider value={{
      news,
      newsReady,
      newsError,
      products,
      productsReady,
      productsError,
      supports,
      supportsReady,
      supportsError,

      // transactions
      dp,
      dpReady,
      dpError,
      wd,
      wdReady,
      wdError
    }}>
      {props.children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;