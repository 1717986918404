import axios from 'axios';
import { set } from 'lodash';

const HOST = process.env.REACT_APP_SOCKET_HOST || 'localhost:3030';

const myaxios = axios.create({
  baseURL: HOST
});

myaxios.interceptors.request.use(function (config) {
  const jwtToken = localStorage.getItem('feathers-jwt');
  set(config, 'headers.Authorization', `JWT ${jwtToken}`);
  return config;
});

export default myaxios;
