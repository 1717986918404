import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import MenuIcon from '@material-ui/icons/Menu';
import { MainListItems, SecondaryListItems, SignOutListItem } from './listItems';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAsync } from 'features/auth/authActions';
import { getName } from 'features/user/userSelectors';
import { isIdle } from 'features/auth/authSelectors';
import RefreshIcon from '@material-ui/icons/Refresh';
import Copyright from 'features/copyright/Copyright';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import WalletIcon from '@material-ui/icons/MonetizationOn';
import GamesIcon from '@material-ui/icons/Casino';
import SupportIcon from '@material-ui/icons/ContactSupport';
import {
  isIOS
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Loader from 'features/loader/Loader';
import {
  useLocation,
  withRouter
} from "react-router-dom";
import bottomLinkMap from './bottomLinkMap';
import { findKey, toNumber } from 'lodash';
import BalanceContext from 'features/balanceContext/balanceContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4) + 50,
  },
  list: {
    width: 250,
  },
  bottomNavigationRoot: {
    width: '100%',
    height: 50,
    position: 'fixed',
    bottom: 0,
  }
}));

function Layout (props) {
  const { content, pageTitle, refreshCb, remoteRefresh } = props;
  const dispatch = useDispatch();
  const name = useSelector(getName);
  const classes = useStyles();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const { t } = useTranslation();
  const DynamicDrawer = isIOS ? Drawer : SwipeableDrawer;
  const { balance } = useContext(BalanceContext);
  const isAuthIdle = useSelector(isIdle);
  const location = useLocation();

  const pageIndexStr = findKey(bottomLinkMap, function(m) {
    return location.pathname === m;
  });
  const [ pageIndex, setPageIndex ] = useState(toNumber(pageIndexStr));

  useEffect(() => {
    // navigating page other than bottom navigator
    if (isNaN(pageIndex)) return;

    const newPathName = bottomLinkMap[pageIndex.toString()];
    if (newPathName === location.pathname) return;
    props.history.push({ pathname: newPathName });
  }, [pageIndex, location.pathname, props.history]);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    dispatch(logoutAsync());
  };

  const handleBalanceClicked = (event) => {
    if (event) event.preventDefault();
    props.history.push({ pathname: '/wallet' });
  };

  return (
    <div className={classes.root}>
      <Loader open={!isAuthIdle} />
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            className={classes.menuButton}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            {pageTitle}
          </Typography>
          <div>
            {
              refreshCb &&
              <IconButton aria-label='refresh' color='inherit' onClick={refreshCb} disabled={remoteRefresh}>
                <RefreshIcon />
              </IconButton>
            }
            <Chip
              icon={<WalletIcon />}
              label={`${balance}`}
              onClick={handleBalanceClicked}
            />
          </div>
        </Toolbar>
      </AppBar>
       <div>
        <React.Fragment>
          <DynamicDrawer
            anchor='left'
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <div
              className={classes.list}
              role='presentation'
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List
                subheader={
                  <ListSubheader component='div' id='user-identity-subheader'>
                    {`${t(`Welcome`)} ${name}`}
                  </ListSubheader>
                }
              >
                <MainListItems />
              </List>
              <Divider light />
              <List>
                <SecondaryListItems />
                <SignOutListItem onClick={handleLogout} />
              </List>
            </div>
          </DynamicDrawer>
        </React.Fragment>

      </div>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          <CssBaseline />
          { content }
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
        <BottomNavigation
            value={pageIndex}
            onChange={(event, newValue) => {
              setPageIndex(newValue);
            }}
            showLabels
            className={classes.bottomNavigationRoot}
          >
            <BottomNavigationAction label={t(`Home`)} icon={<HomeIcon />} />
            <BottomNavigationAction label={t(`Wallet`)} icon={<WalletIcon />} />
            <BottomNavigationAction label={t(`Games`)} icon={<GamesIcon />} />
            <BottomNavigationAction label={t(`Supports`)} icon={<SupportIcon />} />
          </BottomNavigation>
      </main>
    </div>
  );
};

Layout.propTypes = {
  content: PropTypes.element.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default withRouter(Layout);