import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import { getUserId } from 'features/user/userSelectors';

function useCompanyBankLookup () {
  const userId = useSelector(getUserId);
  const cbaService = feathers.service('/company-bank-accounts');
  const [ cba, setCba ] = useState([]);
  const [ status, setStatus ] = useState('fetch');

  useEffect(() => {
    setCba([]);
    setStatus('fetch');
  }, [userId])

  useEffect(() => {
    let isMounted = true;
    if (!userId || status !== 'fetch') return;
    async function fetch () {
      try {
        const findCba = await cbaService.find({ query: { $sort: { name: 1 } }});
        if (isMounted) setCba(findCba.data)
      } catch (err) {} // Ignore error
      if (isMounted) setStatus('idle');
    };
    fetch();

    return () => {
      isMounted = false;
    };
  }, [cbaService, userId, status]);

  return {
    companyBankAccounts: cba,
    ready: status === 'idle'
  }
};

export default useCompanyBankLookup;