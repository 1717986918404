import UserContext from './userContext';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import { get, filter, remove, sortBy } from 'lodash';
import { getUserId } from 'features/user/userSelectors';

function UserProvider (props) {
  const userId = useSelector(getUserId);
  const dpService = feathers.service('/deposit-logs')
  const wdService = feathers.service(`/withdrawal-logs`);
  const cbaService = feathers.service('/customer-bank-accounts');
  const bonusService = feathers.service('/bonus-settings');

  const [ dp, setDp ] = useState([]);
  const [ wd, setWd ] = useState([]);
  const [ bankAccount, setBankAccount ] = useState(null);
  const [ bonusSettings, setBonusSettings ] = useState([]);
  const [ status, setStatus ] = useState('fetch');

  useEffect(() => {
    setDp([]);
    setWd([]);
    setBankAccount(null);
    if (!userId) return;
    else  setStatus('fetch');
  }, [userId]);

  useEffect(() => {
    let isMounted = true;
    if (status !== 'fetch' || !userId) return;

    const query = {
      query: {
        'depositBonus.isReferral': {
          $ne: true
        },
        status: {
          $nin: ['approved', 'rejected']
        },
        $sort: {
          createdAt: -1
        },
        $limit: 1
      }
    };

    async function fetch () {
      try {
        const findDp = await dpService.find(query);
        const findWd = await wdService.find(query);
        const findBankAccount = await cbaService.find();
        const findBonus = await bonusService.find({ query: { $filterMode: true } });

        if (isMounted) {
          setDp(findDp.data);
          setWd(findWd.data);
          findBankAccount.total && setBankAccount(findBankAccount.data[0]);
          setBonusSettings(findBonus.data);
        }
      } catch (err) {} // Ignore error
      if (isMounted) setStatus('idle');
    };
    fetch();

    return () => {
      isMounted = false;
    };
  }, [bonusService, cbaService, dpService, wdService, status, userId]);

  useEffect (() => {
    if (status !== 'idle' || !userId) return;

    const updateBonusUsage = (data) => {
      if (data.status !== 'approved') return;
      const bonusSettingId = get(data, 'depositBonus.setting._id');
      const newBonusSettings = [ ...bonusSettings ];
      const removedBsArr = remove(newBonusSettings, { _id: bonusSettingId });

      if (!removedBsArr.length) return;
      const adjustBs = removedBsArr[0];
      if (adjustBs.applicationLimit === 0 && adjustBs.reapplicationFreqInDays === 0) return;
      adjustBs.leftUsage = adjustBs.leftUsage - 1;

      if (adjustBs.leftUsage > 0) newBonusSettings.push(adjustBs);

      const sorted = sortBy(newBonusSettings, ['name']);
      setBonusSettings(sorted);
    };

    const onDpAddedOrChanged = (data) => {
      updateBonusUsage(data);
      let newData = filter(dp, (d) => {
        return d._id !== data._id
      });
      if (data.status !== 'approved' && data.status !== 'rejected') newData.push(data);
      setDp(newData);
    };

    const onDpDeleted = (data) => {
      let newData = filter(dp, (d) => {
        return d._id !== data._id
      });
      setDp(newData);
    };

    dpService.on('created', onDpAddedOrChanged);
    dpService.on('updated', onDpAddedOrChanged);
    dpService.on('patched', onDpAddedOrChanged);
    dpService.on('removed', onDpDeleted);

    return () => {
      dpService.removeListener('created', onDpAddedOrChanged);
      dpService.removeListener('updated', onDpAddedOrChanged);
      dpService.removeListener('patched', onDpAddedOrChanged);
      dpService.removeListener('removed', onDpDeleted);
    };
  }, [dp, dpService, status, userId, bonusSettings]);

  useEffect (() => {
    if (status !== 'idle' || !userId) return;
    const onWdAddedOrChanged = (data) => {
      let newData = filter(wd, (d) => {
        return d._id !== data._id
      });
      if (data.status !== 'approved' && data.status !== 'rejected') newData.push(data);
      setWd(newData);
    };

    const onWdDeleted = (data) => {
      let newData = filter(wd, (d) => {
        return d._id !== data._id
      });
      setWd(newData);
    };

    wdService.on('created', onWdAddedOrChanged);
    wdService.on('updated', onWdAddedOrChanged);
    wdService.on('patched', onWdAddedOrChanged);
    wdService.on('removed', onWdDeleted);

    return () => {
      wdService.removeListener('created', onWdAddedOrChanged);
      wdService.removeListener('updated', onWdAddedOrChanged);
      wdService.removeListener('patched', onWdAddedOrChanged);
      wdService.removeListener('removed', onWdDeleted);
    };
  }, [wd, wdService, status, userId]);

  useEffect (() => {
    if (status !== 'idle' || bankAccount || !userId) return;

    const onChanged = (data) => {
      setBankAccount(data);
    };

    cbaService.on('created', onChanged);
    cbaService.on('updated', onChanged);
    cbaService.on('patched', onChanged);

    return () => {
      cbaService.removeListener('created', onChanged);
      cbaService.removeListener('updated', onChanged);
      cbaService.removeListener('patched', onChanged);
    };
  }, [status, cbaService, bankAccount, userId]);

  return (
    <UserContext.Provider value={{ dp, wd, bankAccount, bonusSettings, ready: status === 'idle' }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;