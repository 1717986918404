import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Loader from 'features/loader/Loader';
import useCompanyBankLookup from 'hooks/useCompanyBankLookup';
import { get, find, set, unset, toNumber } from 'lodash';
import feathers from 'services/feathers';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import dayjs from 'dayjs';
import UserContext from 'features/userContext/userContext';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});


const useStyles = makeStyles((theme) => ({
  chipRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      borderRadius: 3,
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
      margin: theme.spacing(0.5),
    },
  },
  wagerPaper: {
    padding: theme.spacing(1.5)
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DepositDialog (props) {
  const classes = useStyles();
  const { open, setOpen } = props;
  const { ready: depositLookupReady, companyBankAccounts } = useCompanyBankLookup();
  const { bonusSettings } = useContext(UserContext);
  const { t } = useTranslation();
  const [ bonus, setBonus ] = useState('');
  const [ bank, setBank] = useState('');
  const [ amount, setAmount ] = useState('');
  const [ bonusInfo, setBonusInfo ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ openWagerInfo, setOpenWagerInfo ] = useState(false);

  const handleBonusChange = (event) => {
    event.preventDefault();
    const selected = event.target.value;
    setBonus(selected);
    setOpenWagerInfo(false);
    if (selected) {
      const findBonus = find(bonusSettings, { _id: selected });
      setBonusInfo(findBonus);
    } else {
      setBonusInfo(null);
    }
  };

  const handleBankChange = (event) => {
    event.preventDefault();
    setBank(event.target.value);
  };

  const handleAmountChange = (event) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    if (
      !isFreeBonusSelected() && (!bank || !bonus)
    ) return;

    const data = {
      toAccount: bank,
      depositBonus: {
        setting: bonus
      },
      amount: toNumber(amount)
    };

    if (isFreeBonusSelected()) {
      unset(data, 'toAccount');
      set(data, 'amount', '0');
    }

    try {
      await feathers.service('/deposit-logs').create(data);
      setOpen(false);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const handleViewWager = (event) => {
    event.preventDefault();
    setOpenWagerInfo(!openWagerInfo);
  };

  const generateMinimumAmountText = () => {
    if (bonusInfo && bonusInfo.minDeposit > 0) return t(`Minimum Amount`, { amount: bonusInfo.minDeposit });
    return t(`Amount`);
  };

  const generateBonusInfo = () => {
    let chips = [];

    if (bonusInfo === null) return;
    const type = get(bonusInfo, 'type');
    const amount = get(bonusInfo, 'amount', 0);
    const minDeposit = get(bonusInfo, 'minDeposit');
    const minWithdrawal = get(bonusInfo, 'minWithdrawal');
    const maxWithdrawal = get(bonusInfo, 'maxWithdrawal');
    const maxBonus = get(bonusInfo, 'maxBonus');
    const compensateMode = get(bonusInfo, 'compensateMode', false);
    const wagerMultiplier = get(bonusInfo, 'wagerMultiplier');
    const productsIncluded = get(bonusInfo, 'productsIncluded', []);
    const applyLimit = get(bonusInfo, 'applicationLimit');
    const applyFreq = get(bonusInfo, 'reapplicationFreqInDays');
    const leftUsage = get(bonusInfo, 'leftUsage', 0);
    const endDate = dayjs(get(bonusInfo, 'endDate'));
    const dateNow = dayjs();
    const expiredIn = endDate.diff(dateNow, 'd');

    if (expiredIn > 0 && expiredIn <= 365) {
      chips.push(<Chip key='days-left' size='small' label={t(`daysLeft`, { count: expiredIn })} color='primary' />);
    } else if (expiredIn === 0) {
      chips.push(<Chip key='last-day' size='small' label={t(`Last day`)} color='primary' />);
    }

    if (type === 'fixed' && amount > 0) {
      chips.push(<Chip key='fixed-amt' size='small' label={t(`Free credits`, { amount })} color='primary' />);
    } else if (type === 'percentage' && compensateMode) {
      chips.push(<Chip key='pct-refund' size='small' label={t(`Refund bonus`, { amount })} color='primary' />);
    } else if (type === 'percentage' && !compensateMode) {
      chips.push(<Chip key='pct-deposit' size='small' label={t(`Normal bonus`, { amount })} color='primary' />);
    }

    if (minDeposit === 0) {
      chips.push(<Chip key='free' size='small' label={t(`Deposit free`)} color='primary' />);
    } else {
      chips.push(<Chip key='min-dp' size='small' label={t(`Min deposit`, { amount: minDeposit})} color='primary' />);
    }

    if (minWithdrawal > 0) {
      chips.push(<Chip key='min-wd' size='small' label={t(`Min withdrawal`, { amount: minWithdrawal})} color='primary' />);
    }

    if (maxWithdrawal > 0) {
      chips.push(<Chip key='max-wd' size='small' label={t(`Max withdrawal`, { amount: maxWithdrawal})} color='primary' />);
    }

    if (maxBonus > 0) {
      chips.push(<Chip key='max-bonus' size='small' label={t(`Max bonus`, { amount: maxBonus})} color='primary' />);
    }

    if (applyLimit === 0 && applyFreq === 0) {
      chips.push(<Chip key='free-apply' size='small' label={t(`Free apply`)} color='primary' />);
    } else if (applyLimit > 0 && applyFreq === 0) {
      chips.push(<Chip key='apply-limit' size='small' label={t(`Apply limit`, { times: applyLimit })} color='primary' />);
    } else if (applyFreq > 0 && applyLimit > 0) {
      chips.push(<Chip key='apply-freq' size='small' label={t(`Apply freq`, { times: applyLimit, day: applyFreq })} color='primary' />);
      chips.push(<Chip key='left-usage' size='small' label={t(`Left usage`, { times: leftUsage })} color='primary' />);
    }

    if (wagerMultiplier === 0) {
      chips.push(<Chip key='no-wager' size='small' label={t(`No wager`)} color='primary' />);
    } else {
      chips.push(<Chip key='with-wager' size='small' label={t(`Wager multiplier`, { amount: wagerMultiplier })} color='primary' />);
    }

    if (productsIncluded.length) {
      chips.push(<Chip key='view-wager' icon={<InfoIcon />} size='small' label={t(`View wager`)} onClick={handleViewWager} />);
    }

    return (
      <Grid item xs={12}>
        <div className={classes.chipRoot}>
          {
            chips
          }
        </div>
      </Grid>
    );
  };

  const generateViewWager = () => {
    const productsIncluded = get(bonusInfo, 'productsIncluded', []);
    if (!productsIncluded.length) return null;
    return (
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.wagerPaper}>
          {
            productsIncluded.map(wager => {
              const t = `${wager.product.name} - ${wager.percentage}%`;
              return (
              <Typography key={wager.product._id} variant='subtitle2' display='block'>
                {t}
              </Typography>
              );
            })
          }
        </Paper>
      </Grid>
    );
  };

  const isFreeBonusSelected = () => {
    return (
      bonusInfo && bonusInfo.minDeposit === 0
    );
  };

  return (
    <div>
      <form noValidate autoComplete='off' onSubmit={ (e) => { e.preventDefault(); }}>
        <Dialog onClose={handleClose} aria-labelledby='deposit-dialog-title' open={open} fullWidth>
          <DialogTitle id='deposit-dialog-title' onClose={handleClose}>
            {t(`Deposit`)}
          </DialogTitle>
          <DialogContent dividers>
            <Loader open={!depositLookupReady} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='bonus-select'>{t(`Bonus`)}</InputLabel>
                  <Select
                    native
                    value={bonus}
                    onChange={handleBonusChange}
                    label={t(`Bonus`)}
                    inputProps={{
                      name: 'bonus',
                      id: 'bonus-select',
                    }}
                    autoFocus
                  >
                    <option aria-label='None' value='' />
                    {
                      bonusSettings.map(bs => {
                        return <option key={bs._id} value={bs._id}>{t(bs.name)}</option>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              {
                generateBonusInfo()
              }
              {
                openWagerInfo ? generateViewWager() : null
              }
              {
                !isFreeBonusSelected() && <Grid item xs={12}>
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel htmlFor='bank-select'>{t(`Bank`)}</InputLabel>
                    <Select
                      native
                      value={bank}
                      onChange={handleBankChange}
                      label={t(`Bank`)}
                      inputProps={{
                        name: 'bank',
                        id: 'bank-select',
                      }}
                    >
                      <option aria-label='None' value='' />
                      {
                        companyBankAccounts.map(bank => {
                          const bankName = get(bank, 'bank.name', '');
                          return <option key={bank._id} value={bank._id}>{bankName}</option>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
              }
              {
                !isFreeBonusSelected() && <Grid item xs={12}>
                  <TextField
                    name='amount'
                    variant='outlined'
                    required
                    fullWidth
                    id='amount'
                    label={generateMinimumAmountText()}
                    type='number'
                    value={amount}
                    onChange={handleAmountChange}
                  />
                </Grid>
              }
              {
                errorMessage &&
                <Grid item xs={12}>
                  <Alert severity='error'>{errorMessage}</Alert>
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type='submit' onClick={handleSubmit} color='primary'>
              {t(`Submit`)}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}


DepositDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
