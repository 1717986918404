import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(Backend)
.init({
  backend: {
    // for all available options read the backend's repository readme file
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    //addPath: '/locales/add/{{lng}}/{{ns}}'
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  debug: false,
  react: {
    useSuspense: false
  }
});

export default i18n;