import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { get, split, trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import PhotoIcon from '@material-ui/icons/Photo';
import { grey, red } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import PotView from './PotView';
import FeedbackIcon from '@material-ui/icons/Feedback';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  grey: {
    color: theme.palette.getContrastText(grey[200]),
    backgroundColor: grey[200],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  remarkPaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1.5)
  },
  divider: {
    marginBottom: theme.spacing(2)
  }
}));

export default function TxnLogItem ({ txn, isLast }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ showRemark, setShowRemark ] = useState(false);
  const [ showPot, setShowPot ] = useState(false);

  const bonus = get(txn, 'depositBonus');
  const type = bonus ? 'dp' : 'wd';
  const status = get(txn, 'status');
  const amount = get(txn, 'amount.$numberDecimal', 0);
  const date = dayjs(get(txn, 'createdAt'));
  const bonusName = get(txn, 'depositBonus.setting.name');
  const remark = get(txn, 'remark');
  const splitRemark = split(remark, ']');
  const sanitizedRemark = trim(get(splitRemark, `[${splitRemark.length - 1}]`, ''));
  const potId = get(txn, 'proofOfTransfer');
  const txnId = get(txn, '_id');
  const bonusAmount = get(bonus, 'amount.$numberDecimal', 0);

  const generatePrimaryText = () => {
    if (type === 'wd') return `${amount}`
    if (amount <= 0) {
      return `${t(bonusName)} $${bonusAmount}`
    } else {
      return `${t(bonusName)} $${amount}`
    }
  };

  const generateSecondaryText = () => {
    return `${date.format('YYYY-MM-DD HH:mm')} ${t(status)}`;
  };

  const generateActionIcon = () => {
    let actions = [];

    if (sanitizedRemark) {
      actions.push(
        <IconButton  key={`remark_${txnId}`} color={ showRemark ? 'primary' : 'default' } edge='end' aria-label='comment' onClick={() => { setShowRemark(!showRemark); }}>
          <FeedbackIcon />
        </IconButton>
      );
    }

    if (type === 'wd' && potId) {
      actions.push(
        <IconButton key={`pot_${txnId}`} color={ showPot ? 'primary' : 'default' } edge='end' aria-label='pot' onClick={() => { setShowPot(!showPot); }}>
          <PhotoIcon />
        </IconButton>
      );
    }
    return actions;
  };

  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={ type === 'dp' ? classes.grey : classes.red }>
            {t(`${type}`)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={generatePrimaryText()} secondary={generateSecondaryText()} />
        <ListItemSecondaryAction>
          {
            generateActionIcon()
          }
        </ListItemSecondaryAction>
      </ListItem>
      {
        showRemark &&
        <Paper elevation={2} className={classes.remarkPaper}>
          <Typography variant="h6" gutterBottom>
            {t(`From cs`)}
          </Typography>
          <Divider light className={classes.divider} />
          <Typography variant="body1" gutterBottom>
            {sanitizedRemark}
          </Typography>
        </Paper>
      }
      {
        showPot &&
        <PotView potId={potId} />
      }
      { !isLast && <Divider /> }
    </div>
  );
};

TxnLogItem.propTypes = {
  txn: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired
};
