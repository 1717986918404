import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import { getIsAuthenticated, getErrorMessage, isIdle } from 'features/auth/authSelectors';
import { clearErrorMessageAction } from 'features/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { get, startsWith } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { USER_PENDING_ERROR } from 'constants/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const AuthLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color='primary' />
    </div>
  );
};

export const AuthRedirect = () => {
  const welcomePage = '/home';
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAuthIdle = useSelector(isIdle);
  const pathname = get(location, 'pathname', welcomePage);

  if (!isAuthIdle) return null;
  if (startsWith(pathname, '/signup')) return null;
  if (!isAuthenticated) return <Redirect to={{ pathname: "/signin" }} />;
  if (pathname === '/signin' || pathname === '/') return <Redirect to={{ pathname: welcomePage }} />;
  return <Redirect to={{ pathname: pathname }} />
};

export const AuthError = () => {
  const errorMessage = useSelector(getErrorMessage);
  const dispatch = useDispatch();

  if (!errorMessage || errorMessage === USER_PENDING_ERROR) return null;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(clearErrorMessageAction());
  };

  return (
    <Snackbar
      id='auth-snackbar'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={true}
      autoHideDuration={5000}
      onClose={handleClose}
      message={errorMessage}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );


};