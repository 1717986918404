import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch } from 'react-redux';
import { authenticateAsync } from 'features/auth/authActions';
import { AuthError } from 'features/auth/Auth';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Copyright from 'features/copyright/Copyright';
import useLocaleSelect from 'hooks/useLocaleSelect';
import Activation from 'features/activation/Activation';
import { get } from 'lodash';
import { useLocation, withRouter } from 'react-router-dom';
import { USER_PENDING_ERROR } from 'constants/Constants';
import Loader from 'features/loader/Loader';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Logo from 'assets/images/918kiss.png';

const homeLink = (window.location.hostname.indexOf('.wtf') >= 0 ? 'https://918kiss.wtf' : 'https://918kiss.com.sg');
const supportLink = 'https://api.whatsapp.com/send?phone=601153717563&text=Hi%2C+I+would+like+to+reset+my+password+and+this+is+my+username%3A+';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: '#fafafa',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  langFormControl: {
    margin: theme.spacing(1),
    //width: '100%',
    '& > .MuiInput-root': {
      maxWidth: 120,
    }
  }
}));

const Signin = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const { generateLocaleSelect, localeSelectReady } = useLocaleSelect();
  const [ status, setStatus ] = useState('idle');
  const [ activationNumber, setActivationNumber ] = useState('');
  const [ lastActivation, setLastActivation ] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (status !== 'signIn') return;
    if (!username || !password) return setStatus('idle');

    async function signIn () {
      try {
        await dispatch(authenticateAsync({ username: username, password: password }));
      } catch (err) {
        if (err.message === USER_PENDING_ERROR) {
          const number = get(err, 'data.whatsappActivationNumber');
          if (isMounted && number) {
            setActivationNumber(number);
            setLastActivation(Date.now().toString());
          }
        }
      }
      if (isMounted) setStatus('idle');
    };

    signIn();

    return () => {
      isMounted = false;
    };
  }, [dispatch, status, username, password]);

  useEffect(() => {
    const autoLogin = get(location, 'state.autoLogin', false);
    if (!autoLogin) return;
    const username = get(location, 'state.username');
    const password = get(location, 'state.password');
    setUsername(username);
    setPassword(password);
    setStatus('signIn');
  }, [location]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignInClick = (event) => {
    if (event) event.preventDefault();
    setStatus('signIn');
  };

  return (
    <Container component='main' maxWidth='xs'>
      {
        lastActivation && activationNumber && username &&
        <Activation lastActivation={lastActivation} activationNumber={activationNumber} username={username} />
      }
      <Loader open={!localeSelectReady || status !== 'idle'} />
      <CssBaseline />
      <div className={classes.paper}>
        <Grid
          container
          direction='row-reverse'
        >
          { generateLocaleSelect() }
        </Grid>
        <Link href={homeLink} variant='body2'>
          <Avatar className={classes.avatar} src={Logo} />
        </Link>
        <Typography variant='overline' display='block' gutterBottom>
          {t(`Welcome to 918Kiss Singapore`)}
        </Typography>
        <Typography component='h1' variant='h5'>
          {t(`Sign in`)}
        </Typography>
        <form className={classes.form} noValidate autoComplete='off'>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label={t(`Username`)}
            name='username'
            autoFocus
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label={t(`Password`)}
            type={showPassword ? 'text' : 'password'}
            id='password'
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment:
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={ () => setShowPassword(!showPassword) }
                  onMouseDown={ e => e.preventDefault() }
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            size='large'
            onClick={handleSignInClick}
          >
            {
              t(`Sign in`)
            }
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={`${supportLink}${username}`} variant='body2'>
                {t(`Forgot password?`)}
              </Link>
            </Grid>
            <Grid item>
              <Link href='#' variant='body2' onClick={ (event) => {
                event.preventDefault();
                props.history.push({ pathname: '/signup' });
              }}>
                {t(`No account? Sign Up`)}
              </Link>
            </Grid>
          </Grid>
        </form>
        <AuthError />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(Signin);
