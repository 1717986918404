import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getIsAuthenticated = state => get(state, 'auth.isAuthenticated', false);
export const getStatus = state => get(state, 'auth.status', 'idle');
export const getErrorMessage = state => get(state, 'auth.errorMessage', null);

export const isIdle = createSelector(
  getStatus,
  (status) => (status === 'idle')
);
