import { useState } from 'react';
import dayjs from 'dayjs';

const getLastPrompt = (promptName) => {
  return localStorage.getItem(promptName);
};

const setLastPrompt = (promptName) => {
  const now = dayjs().format();
  localStorage.setItem(promptName, now);
};

const getUserShouldBePrompted = (promptName, coldDownInDay) => {
  const lastPrompt = dayjs(getLastPrompt(promptName));

  const daysSinceLastPrompt = dayjs().diff(lastPrompt, 'd');

  return isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > coldDownInDay;
};

const useShouldShowPrompt = (promptName, coldDownInDay = 1) => {
  const [ userShouldBePrompted, setUserShouldBePrompted ] = useState(getUserShouldBePrompted(promptName, coldDownInDay));

  const handleUserSeeingPrompt = () => {
    setUserShouldBePrompted(false);
    setLastPrompt(promptName);
  };

  return [ userShouldBePrompted, handleUserSeeingPrompt ];
};

export default useShouldShowPrompt;
